import scrollToFieldRegistry from 'fieldFactory/ScrollToField/ScrollToFieldRegistry';
import { formContext as entityFormContext } from 'components/generics/form/EntityFormContext';
import React, { useCallback, useContext, useMemo } from 'react';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import useViewConfig from 'util/hooks/useViewConfig';
import { formTabsContext } from 'components/generics/form/TabbableForm';

const GoToFieldLink: React.FC<{
    field: string;
}> = ({ field, children }) => {
    const topView = useAppSelector((state: RootState) => state.viewStack[0]);
    const viewConfig = useViewConfig();
    const fc = useContext(entityFormContext);
    const viewName = fc.viewName;
    const { setTab } = useContext(formTabsContext);

    const tab = useMemo(() => {
        return Object.entries(viewConfig.views[viewName]?.tabs ?? {}).find(([k, tab]) => {
            return tab.fields?.[field];
        })?.[0];
    }, [viewConfig, field, viewName]);

    const handleClick = useCallback(() => {
        if (tab) {
            if (setTab) {
                setTab(tab);
            } else {
                window.document.getElementById(tab + '-tab')?.click();
            }
        }
        scrollToFieldRegistry.scrollTo((topView ?? 'record-form') + ':' + field);
    }, [topView, tab, field, setTab]);
    return (
        <button onClick={handleClick} className="casetivity-linkbutton">
            {children}
        </button>
    );
};

export default GoToFieldLink;
