import React, { useEffect } from 'react';
import { CASETIVITY_CHAT, REPORT_BUILDER } from './constants';
import { useChatBot } from './ChatbotProvider';
import { createCustomMessage } from 'react-chatbot-kit';
import { useLocation } from 'react-router-dom';

const MessageParser = ({ children, actions }) => {
    const { state: chatState, setConvoUrl, disableInputSendButton } = useChatBot();
    const location = useLocation();

    useEffect(() => {
        if (!chatState.convoUrl) {
            setConvoUrl(location.pathname);
        }
    }, [chatState.convoUrl, location.pathname, setConvoUrl]);

    useEffect(() => {
        if (chatState.convoUrl && location.pathname !== chatState.convoUrl && chatState.assistant !== CASETIVITY_CHAT) {
            const botMessage = createCustomMessage('...', 'pageChange', {});
            actions.updateState(botMessage);
            disableInputSendButton();
        }
    }, [location.pathname, chatState.convoUrl, actions, chatState.assistant, disableInputSendButton]);

    const parse = (message) => {
        switch (chatState.assistant) {
            case CASETIVITY_CHAT:
            case REPORT_BUILDER:
                actions.handleAppChat(message);
                break;
            default:
                console.error('unknown assistant');
                break;
        }
    };

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    parse: parse,
                    actions: {},
                });
            })}
        </div>
    );
};

export default MessageParser;
