import React, { useContext } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { editViewInPlaceContext } from './editViewInPlaceContext';
import { useAppSelector } from 'reducers/rootReducer';
import { useUserCanEditViewDefs } from 'layout-editor/viewdef-editor/useUserCanEditViewDefs';

export default function EditViewInPlaceSwitch() {
    const printMode = useAppSelector((state) => state.printMode);
    const { set, editing } = useContext(editViewInPlaceContext);
    const hasPermission = useUserCanEditViewDefs();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        set(Boolean(event.target.checked));
    };
    if (printMode || !hasPermission) {
        return null;
    }

    return <FormControlLabel control={<Switch checked={editing} onChange={handleChange} />} label="Edit View" />;
}
