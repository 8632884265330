import { AssistantType, CASETIVITY_CHAT, REPORT_BUILDER } from 'chatbot/constants';
import { BACKEND_BASE_URL } from 'config';
import buildHeaders from 'sideEffect/buildHeaders';

interface ReportBuilderRequest {
    message: {
        assistant: string;
        assistantCategory?: string;
        parameters: { input: string; currentConfig?: Record<string, any>; testUrl?: string; testBodyTemplate?: string };
        threadId: string;
        messageId: string;
    };
}

interface CasetivityChatRequest {
    message: {
        assistant: string;
        assistantCategory?: string;
        parameters: {
            input: string;
            additionalParams?: Record<string, any>; // can be more specific here
        };
        threadId: string;
        messageId: string;
    };
}

interface ReportBuilderResponse {
    message: {
        messageId: string;
        parameters: {
            output: string;
            newConfig?: { definition: string };
        };
    };
}

interface CasetivityChatResponse {
    message: {
        messageId: string;
        parameters: {
            output: string;
        };
    };
}
export const assistantConfig = {
    [REPORT_BUILDER]: {
        getUrl: () => `${BACKEND_BASE_URL}api/admin/compass/config`,
        getRequestBody: (
            input: string,
            threadId: string,
            messageId: string,
            additionalParams: { currentConfig?: Record<string, any> },
        ): ReportBuilderRequest => ({
            message: {
                assistant: REPORT_BUILDER,
                assistantCategory: null, /// not sure what to do with this
                threadId,
                messageId,
                parameters: {
                    input,
                    ...additionalParams,
                },
            },
        }),
        handleResponse: (data: ReportBuilderResponse) => ({
            output: data.message.parameters.output,
            newConfig: data.message.parameters?.newConfig,
        }),
    },
    [CASETIVITY_CHAT]: {
        getUrl: () => `${BACKEND_BASE_URL}api/admin/compass/chat`,
        getRequestBody: (input: string, threadId: string, messageId: string): CasetivityChatRequest => ({
            message: {
                assistant: CASETIVITY_CHAT,
                assistantCategory: null, /// not sure what to do with this
                threadId,
                messageId,
                parameters: {
                    input,
                },
            },
        }),
        handleResponse: (data: CasetivityChatResponse) => ({
            output: data.message.parameters.output,
        }),
    },
    // Add more assistants as needed
};

export const createRequestBody = (
    assistant: AssistantType,
    input: string,
    threadId: string,
    messageId: string,
    additionalParams: Record<string, any> = {},
) => {
    const config = assistantConfig[assistant];
    if (!config) throw new Error(`No configuration found for assistant: ${assistant}`);

    return config.getRequestBody(input, threadId, messageId, additionalParams);
};

export const sendRequest = async (
    url: string,
    body: CasetivityChatRequest | ReportBuilderRequest,
    abortController: AbortSignal,
): Promise<CasetivityChatResponse | ReportBuilderResponse> => {
    const request = new Request(url, {
        method: 'POST',
        credentials: 'same-origin',
        headers: buildHeaders({
            includeCredentials: true,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify(body),
        signal: abortController,
    });

    const response = await fetch(request);
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
};
