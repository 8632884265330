import React from 'react';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import get from 'lodash.get';
import pure from 'recompose/pure';
import { numericFormatter } from 'react-number-format';

const getValue = (record, source) => {
    return get(record, source);
};
const hideIfNoRecord = branch(
    (props) => !props.record || (!getValue(props.record, props.source) && props.hideLabel), // record is not provided
    renderNothing,
);

interface CurrencyDisplayProps {
    elStyle: React.CSSProperties;
    record: Record<string, unknown>;
    source: string;
}

const CurrencyDisplay = ({ elStyle = {}, record, source }: CurrencyDisplayProps) => {
    if (!record) return null;
    const value = get(record, source);
    if (value == null) return null;
    const formattedValue = numericFormatter(value.toString(), {
        value,
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
    });
    return <span style={elStyle}>$ {formattedValue}</span>;
};

const CurrencyDisplayField = pure(CurrencyDisplay);

CurrencyDisplayField.CurrencyDisplay = {
    addLabel: true,
};

export default hideIfNoRecord(CurrencyDisplayField);
