import React, { useEffect, useRef, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Card, Button } from '@material-ui/core';
import NavWarning from './components/NavWarning';
import useViewWizardState from '../hooks/useViewWizardState';
import useViewConfig from 'util/hooks/useViewConfig';
import PickViewDef from './components/PickViewDef/PickViewDef';
import { fromNullable, tryCatch } from 'fp-ts/lib/Option';
import { convert } from 'layout-editor/diff-viewsdefs-from-views/convertViewDefToView';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
import { View } from 'reducers/ViewConfigType';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

const Mode = {
    'Pick from ViewDefs': '1',
    'Start from scratch': '2',
};
interface FormData {
    existingViewName?: string;
    mode: string;
}
const Step1 = (props: { viewTypes: View['viewType'][]; nextLocation: string }) => {
    const { state, action } = useViewWizardState();
    const viewConfig = useViewConfig();
    const methods = useForm<FormData>({
        defaultValues: {
            ...state.meta,
            mode: Mode['Pick from ViewDefs'],
        },
        mode: 'onBlur',
    });
    const lastMode = useRef(Mode['Pick from ViewDefs']);
    const { handleSubmit, setValue } = methods;
    const { push } = useHistory();
    useEffect(() => {
        methods.register('existingViewName');
        return () => {
            methods.unregister('existingViewName');
        };
    }, []); // eslint-disable-line
    const existingViewNameValue = methods.watch('existingViewName');
    const existingViewDefData = useMemo(() => {
        if (existingViewNameValue) {
            return fromNullable(viewConfig.viewDefs[existingViewNameValue])
                .chain((vd) => tryCatch(() => JSON.parse(vd.definition)))
                .map((definition) => convert(definition, viewConfig))
                .map((def) => ({ ...def, entityVersion: viewConfig.viewDefs[def.name]?.entityRevision }))
                .toNullable();
        }
        return null;
    }, [existingViewNameValue, viewConfig]);

    // USE BELOW ONCE VIEWDEFS GET SAVED TO VIEWCONFIG.
    // const selectedViewDef = useSelectedData('ViewDef', value);
    const onSubmit = ({ existingViewName, mode }) => {
        // we erase any existing data, since we are at step 1
        if (existingViewDefData) {
            action({
                type: 'pickExisting',
                meta: {
                    existingViewName,
                },
                viewData: existingViewDefData,
            });
            push(props.nextLocation);
        }
        if (mode === Mode['Start from scratch']) {
            action({ type: 'clear' });
            push(props.nextLocation);
        }
    };
    const mode = methods.watch('mode');
    useEffect(() => {
        if (lastMode.current !== mode) {
            lastMode.current = mode;
            setValue('existingViewName', null, {
                shouldDirty: true,
                shouldValidate: true,
            });
        }
    }, [mode, setValue]);
    return (
        <Card style={{ padding: '1em', margin: '1em' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormProvider {...methods}>
                    <NavWarning />
                    <h2>Step 0: New view, or modify an existing one?</h2>
                    <div style={{ height: '1em' }} />
                    <Button color="primary" variant="contained" component={Link} to="/generate-default-views">
                        Generate default views
                    </Button>
                    <div style={{ height: '1em' }} />
                    <label>
                        <h3>Import JSON</h3>
                        <br />
                        <input
                            type="file"
                            accept="application/json"
                            onChange={(e) => {
                                const file: File | undefined = e.target.files && e.target.files[0];
                                if (file) {
                                    const success = (content) => {
                                        try {
                                            const json = JSON.parse(content);
                                            // rough validity heuristic
                                            if (json.name && json.entity && json.viewType) {
                                                action({
                                                    type: 'replace',
                                                    payload: json,
                                                });
                                                push(props.nextLocation);
                                            } else {
                                                alert(
                                                    'Import failed - are you sure this is a valid view? got (first 1000 chars):\n\n' +
                                                        content.slice(0, 1000),
                                                );
                                            }
                                        } catch (e) {
                                            alert('Import failed -  invalid json.');
                                        }
                                    };
                                    const fileReader = new FileReader();
                                    fileReader.onload = (
                                        evt: ProgressEvent & { target: { result?: ArrayBuffer | string } | null },
                                    ) => {
                                        if (evt && evt.target && evt.target.result) {
                                            success(evt.target.result);
                                        }
                                    };
                                    fileReader.readAsText(file);
                                }
                            }}
                        />
                    </label>
                    <div style={{ height: '1em' }} />
                    <h3>OR</h3>
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="mode" name="mode" defaultValue={Mode['Pick from ViewDefs']}>
                            <FormControlLabel
                                value={Mode['Pick from ViewDefs']}
                                control={<Radio inputRef={methods.register} />}
                                label="Modify existing"
                            />
                            <FormControlLabel
                                value={Mode['Start from scratch']}
                                control={<Radio inputRef={methods.register} />}
                                label="Start from scratch"
                            />
                        </RadioGroup>
                    </FormControl>
                    <div style={{ height: '1em' }} />
                    {mode === Mode['Pick from ViewDefs'] ? (
                        <div>
                            <div style={{ height: '1em' }} />
                            <PickViewDef
                                viewName={existingViewNameValue}
                                setViewName={(viewName) => {
                                    setValue('existingViewName', viewName, {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                    });
                                }}
                            />
                        </div>
                    ) : null}
                    <div style={{ margin: '1em', textAlign: 'right' }}>
                        <Button variant="contained" color="primary" type="submit">
                            Submit&nbsp;
                            <NavigateNext />
                        </Button>
                    </div>
                </FormProvider>
            </form>
        </Card>
    );
};

export default Step1;
