import React, { useCallback, useMemo } from 'react';
import { createCustomMessage } from 'react-chatbot-kit';

interface ActionProviderProps {
    setState: Function;
    children: JSX.Element[];
}

const ActionProvider = ({ setState, children }: ActionProviderProps) => {
    const updateState = useCallback(
        (
            botMessage:
                | string
                | {
                      loading?: boolean;
                      widget?: string;
                      delay?: number;
                      payload?: any;
                      message: string;
                      type: string;
                      id: number;
                  },
            other?,
        ) => {
            setState((prev) => ({
                ...prev,
                ...other,
                messages: [...prev.messages, botMessage],
            }));
        },
        [setState],
    );
    // createCustomMessage => inital message disappears once widget has something to render
    // createChatBotMessage => initial message stays in chat

    const handleAppChat = useCallback(
        (message) => {
            const botMessage = createCustomMessage('...', 'appChat', { payload: { message, updateState } });
            updateState(botMessage);
        },
        [updateState],
    );

    const actions = useMemo(
        () => ({
            handleAppChat,
            updateState,
        }),
        [handleAppChat, updateState],
    );

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: actions,
                });
            })}
        </div>
    );
};

export default ActionProvider;
