import React, {
    FunctionComponent,
    useState,
    useEffect,
    useCallback,
    useContext,
    useMemo,
    useReducer,
    useRef,
} from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import createGetDefaultValues from '../utils/getDefaultValuesEditForm';
import Toolbar from 'components/generics/form/Toolbar.aor';
import TabbableForm from '../form/TabbableForm';
import { createTabsWithErrorsSelector } from '../utils/viewConfigUtils/index';
import { RootState, useAppSelector, useAppStore } from '../../../reducers/rootReducer';
import { DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import MergeView from '../genericMerge/index';
import getBooleanFieldsPreloadedFalse from '../utils/getBooleanFieldsPreloadedFalse';
import { formContext } from '../form/EntityFormContext';
import deepSubmit from 'util/deepSubmit';
import { disableAllFieldsContext, DisableAllFieldsContextProvider } from '../form/forceDisableAllFieldsContext';
import {
    InjectErrorsToAllFieldsContextProvider,
    TestingTools,
} from 'components/generics/form/forceInjectErrorsForAllFieldsContext';
import ViewConfig from 'reducers/ViewConfigType';
import { AjaxError } from 'rxjs/ajax';
import ErrorDialog from '../form/SubmissionFailureDialog';
import FormSaveNotifierTrigger from 'formSaveNotifier/components/Trigger';
import EntityExpressionTester, { RenderLayoutEditor } from 'expression-tester/entity-form';
import getConflictingEdits from '../hoc/getConflictingEdits';
import getFields from './getFields';
import useEntitiesAreLoading from 'util/hooks/useEntitiesAreLoading';
import useViewConfig from 'util/hooks/useViewConfig';
import useWidth from 'util/hooks/useWidth';
import { fromNullable } from 'fp-ts/lib/Option';
import { EntityValidations } from 'reducers/entityValidationsReducer';
import { SpelOptions } from 'expressions/evaluate';
import { FieldFactoryContext } from 'fieldFactory/Broadcasts';
import { ValueSets } from 'valueSets/reducer';
import useEntities from 'util/hooks/useEntities';
import useValueSets from 'util/hooks/useValueSets';
import { CasetivityViewContextProvider, casetivityViewContext } from 'util/casetivityViewContext';
import { FormFieldUnion } from 'fieldFactory/translation/fromFlowable/types';
import { createGetEntities } from '../form/EntityFormContext/util/getEntities';
import { useGetData } from './useGetData';
import useValidation from '../form/validate/useValidation';
import produce from 'immer';
import traverse from 'traverse';
import set from 'lodash/set';
import get from 'lodash/get';
import merge from 'lodash/merge';
import useFieldsByTab from '../form/hooks/useFieldsByTab';
import isPlainObject from 'lodash/isPlainObject';
import deepEql from 'deep-eql';
import removeUndefineds from 'util/removeUndefineds';
import { nestingContext } from 'fieldFactory/offline/nestingContext';
import { useRecord } from '../genericCreate/useRecord';
import { ParentBackRefProperties } from '../genericCreate/ParentBackRefProperties';
import moment from 'moment';
import { REPLACE_STATE } from 'actions/constants';
import { useGetCasetivityRemovedFields } from '../form/EnhancedRGridTabbable';
import isOffline from 'util/isOffline';
import { addOfflineMeta, removeOfflineMeta } from 'offline_app/offline_stateful_tasks/offlineMeta/actions';
import flatten from 'flat';
import useTitleElement from '../form/hooks/useTitleElement';
import modifySubmissionValues from './modifySubmissionValues';
import useOverriddenViewValidations from '../form/hooks/useOverriddenViewValidations';
import { EntityFormContextRef } from 'bpm/components/TaskDetail/TaskForm/TaskForm/types';
import { useMinimalRecord } from './useMinimalRecord';
import { useIsPopover } from './useIsPopover';
import { getSubmissionError } from 'sideEffect/other/notificationEpic';
import { WithDefaultValues } from '../genericCreate/useDefaultValues';
import Dialog from '@mui/material/Dialog';

// allows unknown fields,
// just strips out known 'non-editable' fields
const stripFieldsWithoutEditPermission = (viewConfig: ViewConfig) => {
    const stripFieldsWithoutEditPermissionInner = (rootEntity: string, data: {}) => {
        return Object.fromEntries(
            Object.entries(data).flatMap(([k, v]): [string, any][] => {
                if (k.startsWith('_')) {
                    return [];
                }
                const field = viewConfig.entities[rootEntity].fields[k];
                if (!field) {
                    return [[k, v]]; // if the field can't be looked up, lets assume it's there on purpose.
                }
                const { name } = field;

                if (name === 'id' || name === 'entityType' || name === 'entityVersion') {
                    // these are non-editable, but we still want them
                    return [[k, v]];
                }

                // what we really care above is removing known fields,
                // with known accessLevels we don't need to submit.
                if (isPlainObject(v)) {
                    const { relatedEntity } = field;
                    if (relatedEntity) {
                        const strippedChild = stripFieldsWithoutEditPermissionInner(relatedEntity, v);
                        return [[k, strippedChild]];
                    }
                    return [[k, v]];
                }
                if (
                    // note:  One could argue we might actually want to allow sending this as a 'create' expansion.
                    // however in practice we don't allow editing of these 'create-only' fields in edit forms.
                    field.accessLevel < 3 // doesn't have edit permission for this field
                ) {
                    return [];
                }
                return [[k, v]];
            }),
        );
    };
    return stripFieldsWithoutEditPermissionInner;
};

const adjustForSubmit = (
    viewConfig: ViewConfig,
    viewName: string,
    valuesForRegisteredFieldsOnly: {},
    record: {
        id: string;
        entityType: string;
        entityVersion: number;
    },
    initialValues: {},
) => {
    /*
    The job of the code below is to send data required for the validations to run, because backend validation
    doesn't support PATCH / doesn't look up the fields it needs for validation from the DB if missing.
    However the problem is we end up expanding trees of data we don't need, which can cause merge conflicts, or
    undecipherable errors

    In the meantime the below is commented out, and saves will fail if a field is missing from the save.
    */
    /*
    const entityValidations: EntityValidations[0] | undefined = props.entityConfigValidations;
    const requiredFields = uniq(setoidString)(
        getBaseFields(
            (entityValidations || []).flatMap(({ fieldsRequired }) => fieldsRequired)
        )
    );
    */
    /*
        boolean fields in the view should be set to 'false' if not present/undefined/null
    */
    const resource = viewConfig.views[viewName].entity;
    const values = merge(
        {
            id: record.id,
            entityVersion: record.entityVersion,
            entityType: record.entityType,
        },
        getBooleanFieldsPreloadedFalse(viewConfig, viewName),
        valuesForRegisteredFieldsOnly,
    );

    Object.entries(values).forEach((t) => {
        const [key, value] = t;
        /*
            If a nested object is deeply equal to a nested object in our initial data,
            lets NOT SUBMIT IT.
            There's no reason to, and in cases where we have fields like
            outbreakSurvey.dryIceAccess
            where outbreakSurvey doesn't exist, if we submit the nested object it might crash.
            (In the above scenario the initialValues might contain outbreakSurvey solely as a way
            to initialize boolean fields as false.
            e.g. { outBreakSurvey: { dryIceAccess: false }} just because we need a false value there
            for our boolean widget) 
        */
        const initialValue = initialValues?.[key];
        if (
            isPlainObject(value) &&
            isPlainObject(initialValue) &&
            deepEql(removeUndefineds(value), removeUndefineds(initialValue))
        ) {
            delete values[key];
        }

        /*
        This removes the referenced object if the id changes.
        Since we allow nested submits, we may want to change the id but also save nested data.
        Therefore this is commented out.

        if (key.endsWith('Id') &&
            props.record[key] !== value &&
            Object.prototype.hasOwnProperty.call(values, key.slice(0, -2))) {
            delete values[key.slice(0, -2)];
        }
        */

        /*
        Uncommenting below removes many-manys because many-many fields are now in 'commitChanges' mode).
        */
        // if (key.endsWith('Ids') && isRefManyManyField(viewConfig, resource, key.slice(0, -3), 'TRAVERSE_PATH')) {
        //     delete values[key];
        // }
    });
    // recurse, delete items we have accessLevel < 3
    const withoutNonEditable = stripFieldsWithoutEditPermission(viewConfig)(resource, values);
    return deepSubmit(withoutNonEditable);
};

const _VALIDATION_HACK_KEY = '_validationHack';

interface EditForm2Props {
    // for when we have an 'offline created' record where the backref should be non-editable and it is technically a create
    backref?: ParentBackRefProperties;
    overrideInitialValues?: {};
    id: string;
    disableFormSaveNotifier?: boolean;
    taskId?: string; // we use this to look up taskform data in case it specifies to initialize using that saved value.
    optInWriteable: {
        [field: string]: FormFieldUnion;
    };
    referenceFieldsShouldFetchInitialData?: boolean;
    createMobileAppBar?: boolean;
    resource: string;
    tabToPrefetch?: string;
    viewName: string;
    save: (
        values: {},
        redirect: string | undefined,
        onSaveOrFailureCb: (err?: AjaxError) => void,
        visibleAndEditableFields?: string[],
    ) => void;
    redirect?: string | false;
    toolbar?: React.ReactElement<{
        handleSubmitWithRedirect: (redirect: string) => Function; // <-fix this definition
        invalid: boolean;
        submitOnEnter: boolean;
        saving?: boolean;
    }>;
    actions: React.ReactElement<{ isSaving?: boolean; save: () => void }>;
    submitOnEnter?: boolean;
    formId?: string;
    useTabs?: boolean;
    renderLayoutEditor?: RenderLayoutEditor;
    renderAboveForm?: () => JSX.Element;
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
    entityFormContextRef?: EntityFormContextRef;
    disallowClickAwayNavigation?: boolean;
    defaultValues?: Record<string, unknown>;
}

interface EditForm2InnerProps extends EditForm2Props {
    baseFields: React.ReactElement<{ source: string }>[];
    fieldsByTab: {
        [tab: string]: React.ReactElement<{ source: string }>[];
    };
}

export const useIsTop = (formId?: string) => {
    return useAppSelector((state: RootState) => (formId ? state.viewStack[0] === formId : true));
};
const contentContainerStyle = { borderTop: 'solid 1px #e0e0e0' };
const useIsCurrentlyWritingToOffline = (taskId?: string) => {
    return useAppSelector((state: RootState) => (taskId ? state.taskCurrentlyWritingToOffline === taskId : false));
};

const EditForm2: FunctionComponent<EditForm2InnerProps & InjectedFormProps> = React.memo((props) => {
    const {
        change,
        viewName,
        save,
        baseFields,
        fieldsByTab,
        handleSubmit,
        redirect,
        invalid,
        submitOnEnter,
        id,
        resource,
        useTabs = true,
        disableFormSaveNotifier = false,
        taskId,
        renderLayoutEditor,
        renderAboveForm,
        disallowClickAwayNavigation,
    } = props;
    const isPopover = useIsPopover(props.form);
    const fc = useContext(formContext);
    const casetivityRemovedFields = useGetCasetivityRemovedFields(fc);
    const [isSaving, setIsSaving] = useState(false);
    const viewConfig = useViewConfig();
    const isLoading = useEntitiesAreLoading();
    const { dataLoaded, minimalRecord } = useMinimalRecord(resource, id);
    const [alertError, setAlertError] = useState<AjaxError | null>();
    const taskFormIsSaving = useAppSelector((state: RootState) =>
        taskId ? state.bpm.tasks.submitting[taskId] ?? false : false,
    );
    const isCurrentlyWritingToOffline = useIsCurrentlyWritingToOffline(taskId);
    useEffect(() => {
        props.initialize(props.initialValues);
        if (dataLoaded) {
            props.change(_VALIDATION_HACK_KEY, Date.now());
        }
    }, []); // eslint-disable-line
    useEffect(() => {
        if (!isLoading) {
            change(_VALIDATION_HACK_KEY, Date.now());
        }
    }, [isLoading]); // eslint-disable-line
    const initialHiddenFields = fc.initialFormContext?.hiddenFields;
    const saveRegisteredFields = useCallback(
        (redirect: string | undefined, onSubmittingCb: () => void, onSaveOrFailureCb?: (err?: AjaxError) => void) => {
            return handleSubmit((values) => {
                onSubmittingCb();
                const valuesWithIdsAdjustedForNesting = adjustForSubmit(
                    viewConfig,
                    viewName,
                    fc.registeredValues,
                    minimalRecord,
                    fc.initialValues,
                );
                const finalValues = modifySubmissionValues({
                    hiddenFields: fc.hiddenFields,
                    viewConfig,
                    viewName,
                    values: valuesWithIdsAdjustedForNesting,
                    casetivityRemovedFields,
                    initialHiddenFields,
                });
                return new Promise<AjaxError>((res, rej) => {
                    save(finalValues, redirect, res);
                }).then((e: AjaxError) => {
                    onSaveOrFailureCb(e);
                });
            });
        },
        [
            fc.hiddenFields,
            handleSubmit,
            viewConfig,
            viewName,
            fc.registeredValues,
            fc.initialValues,
            minimalRecord,
            casetivityRemovedFields,
            save,
            initialHiddenFields,
        ],
    );

    const allFieldSources = useMemo(() => {
        return props.baseFields
            .concat(Object.values(props.fieldsByTab).flat())
            .map((f) => f.props.source)
            .filter(Boolean)
            .reduce((prev, curr) => {
                prev[curr] = true;
                return prev;
            }, {} as { [key: string]: true });
    }, [props.baseFields, props.fieldsByTab]);

    const handleSubmitWithRedirect = React.useCallback(
        (_redirect?: string) => {
            const __redirect = redirect || _redirect;
            return saveRegisteredFields(
                __redirect,
                () => setIsSaving(true),
                (err) => {
                    setIsSaving(false);
                    // we handle 409 errors further up
                    if (err && err.status !== 409) {
                        // before we waited until 'isSaving' updated before setting alertError. Consider this.
                        setAlertError(err);
                        throw getSubmissionError(err, allFieldSources);
                    }
                },
            );
        },
        [redirect, setIsSaving, setAlertError, saveRegisteredFields, allFieldSources],
    );
    const clearAlert = React.useCallback(() => {
        setAlertError(null);
    }, [setAlertError]);
    const toolbar: EditForm2InnerProps['toolbar'] = props.toolbar || (
        <Toolbar handleSubmitWithRedirect={handleSubmitWithRedirect} invalid={invalid} submitOnEnter={submitOnEnter} />
    );
    const actions = React.useMemo(
        () =>
            React.cloneElement(props.actions, {
                isSaving,
                save: handleSubmitWithRedirect(),
            }),
        [isSaving, handleSubmitWithRedirect, props.actions],
    );
    const width = useWidth();
    const tabsWithErrorsSelector = useMemo(() => createTabsWithErrorsSelector(props.formId), [props.formId]);
    const tabsWithErrors = useAppSelector((state) => tabsWithErrorsSelector(state, props));
    const displayTitleAboveWidth = props.createMobileAppBar ? 'xs' : undefined;
    const { TitleElem, EditTitleElem } = useTitleElement({ displayAboveWidth: displayTitleAboveWidth, viewName, id });

    return (
        <React.Fragment>
            <ErrorDialog alertError={alertError} clearAlert={clearAlert} resource={resource} />
            {renderLayoutEditor?.()}
            <TabbableForm
                isShow={false}
                disallowClickAwayNavigation={disallowClickAwayNavigation}
                renderAbove={renderAboveForm}
                isPopover={isPopover}
                isLoading={isLoading}
                width={width}
                title={
                    EditTitleElem ? (
                        <div style={{ display: 'flex' }}>
                            {TitleElem}&nbsp;{EditTitleElem}
                        </div>
                    ) : (
                        TitleElem
                    )
                }
                actions={
                    process.env.NODE_ENV === 'development' ? (
                        <React.Fragment>
                            <disableAllFieldsContext.Consumer>
                                {(c) => (
                                    <button type="button" onClick={() => c.set(!c.disabled)}>
                                        {c.disabled ? 'undisable fields' : 'Debug: Disable all fields'}
                                    </button>
                                )}
                            </disableAllFieldsContext.Consumer>
                            <TestingTools />
                            {actions}
                        </React.Fragment>
                    ) : (
                        actions
                    )
                }
                record={minimalRecord}
                viewName={viewName}
                viewConfig={viewConfig}
                toolbar={
                    toolbar &&
                    React.cloneElement(toolbar, {
                        handleSubmitWithRedirect,
                        invalid,
                        submitOnEnter,
                        saving: isSaving,
                    })
                }
                contentContainerStyle={contentContainerStyle}
                useTabs={useTabs}
                baseFields={baseFields}
                fieldsByTab={fieldsByTab}
                tabsWithErrors={tabsWithErrors}
            />
            <FormSaveNotifierTrigger
                when={
                    !isCurrentlyWritingToOffline &&
                    !disableFormSaveNotifier &&
                    fc.isDirty &&
                    !(isSaving || taskFormIsSaving)
                }
            />
        </React.Fragment>
    );
});
type MergeState =
    | {
          type: 'none';
      }
    | {
          type: 'merge';
          mergeNew: {};
          mergeOld: {};
      };
type MergeAction =
    | {
          type: 'set';
          mergeNew: {};
          mergeOld: {};
      }
    | {
          type: 'clear';
      };
const EditForm2WithMerge: React.ComponentType<EditForm2InnerProps & InjectedFormProps> = React.memo((props) => {
    const viewConfig = useViewConfig();
    const [mergeState, dispatchMerge] = useReducer(
        (state: MergeState, action: MergeAction) => {
            switch (action.type) {
                case 'clear':
                    return { type: 'none' };
                case 'set':
                    return {
                        type: 'merge',
                        mergeNew: action.mergeNew,
                        mergeOld: action.mergeOld,
                    };
            }
        },
        { type: 'none' },
    );

    const activeField = useAppSelector((state: RootState) =>
        fromNullable(state.form[props.form])
            .mapNullable((f) => f.active)
            .toUndefined(),
    );
    const currentFormValues = useAppSelector((state: RootState) => {
        // don't keep passing new objects while the user is inputting // used to merge with new values initialization
        return (
            !activeField &&
            fromNullable(state.form[props.form])
                .mapNullable((f) => f.values)
                .toUndefined()
        );
    });
    const fc = useContext(formContext);
    const store = useAppStore();
    const getEntities = useMemo(createGetEntities, []);
    const id: -1 | string = useAppSelector((state: RootState) => {
        return fromNullable(getEntities(state))
            .mapNullable((e) => e[props.resource])
            .mapNullable((r) => r[props.id])
            .mapNullable((r) => r.id)
            .getOrElse(-1);
    });
    const previousInitialValues = React.useRef(props.initialValues);

    const calcConflicts = useCallback<() => ReturnType<typeof getConflictingEdits> | null>(() => {
        if (previousInitialValues?.current && props.initialValues && fc.registeredValues) {
            const visibleAndEditableRegisteredValues = fc.visibleAndEditableFields.reduce((prev, curr) => {
                const value = get(fc.registeredValues, curr);
                if (typeof value !== 'undefined') {
                    set(prev, curr, value);
                }
                return prev;
            }, {});

            const res = getConflictingEdits(
                previousInitialValues.current,
                props.initialValues,
                visibleAndEditableRegisteredValues,
            );
            if (res) {
                // To make this easier to debug
                console.log({
                    visibleAndEditableRegisteredValues,
                    'previousInitialValues.current': previousInitialValues.current,
                    'props.initialValues': props.initialValues,
                    fc,
                    res,
                });
            }
            return res;
        }
        return null;
    }, [props.initialValues, fc]);
    React.useEffect(() => {
        const conflictingEdits = calcConflicts();
        const prevInitial = previousInitialValues.current;
        previousInitialValues.current = props.initialValues;
        if (conflictingEdits) {
            if (props.taskId && store.getState().taskCurrentlyWritingToOffline === props.taskId) {
                store.dispatch(
                    addOfflineMeta(props.taskId, {
                        linkedEntity: {
                            patchLinkedEntityInitialValues: {
                                id: props.id,
                                entityType: props.resource,
                                ...Object.fromEntries(
                                    Object.entries(conflictingEdits.formValues).map(([k, v]) => [k, prevInitial[k]]),
                                ),
                            },
                        },
                    }),
                );
            }
            dispatchMerge({
                type: 'set',
                mergeNew: conflictingEdits.formValues,
                mergeOld: conflictingEdits.newInitialValuesConflictedWith,
            });
        }
    }, [calcConflicts, props.initialValues, props.id, props.resource, store, props.taskId]);
    const handleClearMergeConflict = React.useCallback(() => {
        store.dispatch(removeOfflineMeta());
        dispatchMerge({ type: 'clear' });
    }, [dispatchMerge, store]);
    const isCurrentlyWritingToOffline = useIsCurrentlyWritingToOffline(props.taskId);
    const mergeText = (
        <>
            <p>{'The record you are working on has been modified by another user.'}</p>
            <p>
                {
                    ' Please select which information the system should save by clicking on the checkbox in one of the first two columns.'
                }
                {
                    ' You may enter new information in the "Resolved Values" column if neither the first nor second column is correct.'
                }
            </p>
            {isCurrentlyWritingToOffline ? (
                'The "Resolved Values" will be retained and overwrite your working draft.'
            ) : (
                <>
                    <b>{'Changes you have made have not been saved.'}</b>
                    {' Please make the necessary changes and resubmit.'}
                </>
            )}
        </>
    );
    return (
        <>
            <EditForm2 {...props} />
            {!activeField && mergeState.type === 'merge' && (
                <Dialog
                    TransitionProps={
                        {
                            role: 'presentation',
                        } as any
                    }
                    maxWidth={false}
                    fullWidth={true}
                    open={true}
                >
                    <DialogTitle style={{ textAlign: 'center' }}>Action Required: Conflicting Edits</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ textAlign: 'center' }}>{mergeText}</DialogContentText>
                        <MergeView
                            wrapInCard={false}
                            record1={mergeState.mergeOld}
                            record2={mergeState.mergeNew}
                            record={mergeState.mergeOld}
                            resource={props.resource}
                            viewConfig={viewConfig}
                            viewName={props.viewName}
                            basePath={`/${props.resource}`}
                            match={{
                                params: {
                                    id,
                                },
                            }}
                            primaryRecordTitle="Changes made by another user while you were editing."
                            altRecordTitle="Your current work."
                            merge={(values) => {
                                handleClearMergeConflict();
                                const state = store.getState();

                                const newState = produce(state, (draftState) => {
                                    draftState.form[props.form].values = merge({}, currentFormValues, values);
                                    return draftState;
                                });
                                (store.dispatch as any)({
                                    type: REPLACE_STATE,
                                    payload: newState,
                                });
                            }}
                            includeRefManys={false}
                            onlyFields={mergeState.mergeNew && Object.keys(flatten(mergeState.mergeNew))}
                        />
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
});

interface EditForm2FormProps extends EditForm2InnerProps {
    fieldValues?: { id: string };
    entityConfigValidations: EntityValidations;
    entities: any;
    options: SpelOptions;
    taskId: string | undefined;
    valueSets: ValueSets;
    viewConfig: ViewConfig;
    initialValues: {};
    form: string;
    valuesForRegisteredFieldsOnly: {};
    _validate: (props: { id: string; fieldValues: any }) => {};
    _warn: (props: { id: string; fieldValues: any }) => {};
}
const should = (prop: '_validate' | '_warn') => {
    return ({ values, props, nextProps, initialRender }) => {
        const res =
            initialRender ||
            !props.anyTouched ||
            nextProps.fieldValues !== props.fieldValues ||
            nextProps[prop] !== props[prop];

        return res;
    };
};
const EditForm2Form: React.ComponentType<EditForm2FormProps> = reduxForm<{}, EditForm2FormProps>({
    enableReinitialize: true,
    updateUnregisteredFields: true,
    keepDirtyOnReinitialize: true,
    shouldError: should('_validate'),
    shouldWarn: should('_warn'),
    warn: (values, props) => {
        const { _warn, fieldValues, id } = props;
        return _warn({ id, fieldValues });
        // we have to pass these to make sure we are still in-sync with our warning callback
    },
    validate: (values, props) => {
        const { _validate, fieldValues, id } = props;
        // we have to pass these to make sure we are still in-sync with our validation callback
        return _validate({ id, fieldValues });
    },
})(
    React.memo((props: EditForm2FormProps & InjectedFormProps) => {
        const {
            entities,
            entityConfigValidations,
            valuesForRegisteredFieldsOnly,
            fieldValues,
            valueSets,
            viewConfig,
            _validate,
            ...rest
        } = props;
        return <EditForm2WithMerge {...rest} />;
    }),
);

const useValidate = ({
    type,
    values,
    resource,
    registeredFields,
    extraValidations,
    adhocVariablesContext,
}: {
    extraValidations?: EntityValidations[0];
    type: 'error' | 'warn';
    values: {};
    resource: string;
    registeredFields?: string[];
    adhocVariablesContext: Record<string, unknown>;
}) => {
    const vr = useValidation({
        type: 'error',
        values,
        resource,
        registeredFields,
        extraValidations,
        adhocVariablesContext,
    });
    const validate = useCallback(
        (props: { id: string; fieldValues: any }) => {
            // when changing views, can get 'old' fieldValues' stuck around for one update.
            // Check we are in sync, because we can try to traverse non-existent datapaths if entityType changed.
            if (props.id && props.id === props.fieldValues.id) {
                try {
                    /*
                        When we are a linked-entity in a form with linked-fields, we can get our data initialized before our entities are set up.
                        To deal with that rare edge case, lets just catch the error.
                    */
                    const res = vr();
                    return res;
                } catch (e) {
                    console.error(e);
                    return {};
                }
            }
            return {};
        },
        [vr],
    );
    return validate;
};

const EditForm2Wrapper: FunctionComponent<EditForm2Props> = React.memo((props) => {
    const initialTabToPrefetch = useRef(props.tabToPrefetch);
    const {
        save,
        viewName,
        resource,
        id,
        formId,
        referenceFieldsShouldFetchInitialData,
        optInWriteable,
        disableFormSaveNotifier,
        redirect,
        taskId,
        backref,
        renderLayoutEditor,
        renderAboveForm,
        disallowClickAwayNavigation,
        defaultValues,
    } = props;
    const fieldFactory: any = useContext(FieldFactoryContext);
    const form = props.formId || 'record-form';
    const printMode = useAppSelector((state: RootState) => state.printMode);
    const { disabled: DEBUG_disableAllFields } = useContext(disableAllFieldsContext);
    const viewConfig = useViewConfig();
    const isPopover = useIsPopover(formId);
    const getData = useGetData();

    const { entriesAtCurrentLevel: _entriesAtCurrentLevel, path } = useContext(nestingContext);
    const t = useAppSelector((state: RootState) => state.entitySubmitsInTaskContext);
    let entriesAtCurrentLevel = (() => {
        if (path.length === 0) {
            if (t.type === 'loaded') {
                return t.entries;
            }
        }
        return _entriesAtCurrentLevel;
    })();

    const offlineDownloadedListViews = useAppSelector((state: RootState) => state.offlineDownloadedListViews);
    const offlineDownloadedRef1Views = useAppSelector((state: RootState) => state.offlineDownloadedRef1Views);

    const offlineDownloadedFields = useMemo(() => {
        /**
         * offlineDownloadedListViews etc. are kept around, so we need to be careful they aren't present except in
         * the offline app, where if we are on a route, we have the guarantee they were applied from the saved state.
         */

        if (!isOffline()) {
            return null;
        }
        if (moment(id).isValid()) {
            // pending create - show all of them.
            return null;
        }
        if (!offlineDownloadedListViews && !offlineDownloadedRef1Views) {
            return null;
        }
        return { ...offlineDownloadedListViews?.[resource]?.[id], ...offlineDownloadedRef1Views?.[resource]?.[id] };
    }, [offlineDownloadedListViews, offlineDownloadedRef1Views, id, resource]);

    const _backrefRecord = useRecord(backref);
    const { baseFields, fieldsByTab: _fieldsByTab = {} } = React.useMemo(
        () => {
            // field needs a subscription to 'record'
            const defaultRecord = {
                id,
                entityType: resource,
            };
            return getFields(
                fieldFactory,
                {
                    tabToPrefetch: initialTabToPrefetch.current,
                    printMode,
                    referenceFieldsShouldFetchInitialData,
                    optInWriteable,
                    basePath: `/${resource}`,
                    record: { ...defaultRecord, ..._backrefRecord },
                    match: {
                        params: {
                            id,
                        },
                    },
                    resource,
                    formId,
                    DEBUG_disableAllFields,
                    isPopover,
                    viewConfig,
                    viewName,
                    reviewOfflineDataMode: entriesAtCurrentLevel,
                    backref,
                    offlineDownloadedFields,
                    expandComponents: true,
                },
                (state: RootState) => {
                    return {
                        record:
                            getData(state, {
                                viewName,
                                id,
                                overrideViewConfig: viewConfig,
                            }) || defaultRecord,
                    };
                },
            );
        } /* eslint-disable */,
        [
            _backrefRecord,
            getData,
            backref,
            id,
            formId,
            isPopover,
            viewConfig,
            viewName,
            fieldFactory,
            DEBUG_disableAllFields,
            printMode,
            referenceFieldsShouldFetchInitialData,
            optInWriteable,
            resource,
            entriesAtCurrentLevel,
        ],
    );
    const fieldsByTab = useFieldsByTab(_fieldsByTab, 'edit');
    /* eslint-enable */
    const fc = useContext(formContext);
    const entityConfigValidations = useAppSelector((state: RootState) => state.entityValidations);
    const getDefaultValues = useMemo(createGetDefaultValues, []);
    // TODO: fix: add fieldsByTab to getDefaultValues as well
    // (or just refactor that piece of code from aor)
    const _initialValues = useAppSelector(
        (state: RootState) =>
            props.overrideInitialValues ?? getDefaultValues(state, { ...props, overrideViewConfig: viewConfig }),
    );
    const initialValues = useMemo(() => {
        if (!defaultValues) {
            return _initialValues;
        }
        return merge({}, _initialValues, defaultValues);
    }, [defaultValues, _initialValues]);
    const entities = useEntities();
    const valueSets = useValueSets();
    const { rootViewContext: viewContext } = useContext(casetivityViewContext);
    const dateFormat = useAppSelector((state: RootState) => state.viewConfig.application.dateFormat);
    const options: SpelOptions = useMemo(() => ({ dateFormat, viewContext }), [dateFormat, viewContext]);

    const notInSync = Boolean(
        (fc.fieldValues as { entityType?: string }).entityType &&
            (fc.fieldValues as { entityType?: string }).entityType !== resource,
    );
    const valuesToValidateOn = useMemo(() => {
        // fieldValues can get out of sync with our props due to redux-form update cycle
        // this can happen when switching from a process page to task page where each has a different linkedEntity shown
        if (notInSync) {
            return {};
        }
        /* The problem is fieldValues won't necessarily include empty values (especially on the initial data pull),
            and so we won't pull in validations on those missing fields.
            e.g. if there's no labResult.zip key in our values, we won't pull in that validation.
            Instead, we need to null-initialize these based on items in registeredValues with undefined values.
            (registeredValues maps our 'official' fields onto values, so they will include e.g. labResult.zip: undefined)

            There are also some items in fieldValues we want, which might not be mapped onto fields,
            like nested ids, entityTypes, versions
        */
        return produce(fc.fieldValues, (draftValues) => {
            traverse(fc.registeredValues).forEach(function (x) {
                if (typeof x === 'undefined' || x === null) {
                    const dottedPath = this.path.join('.');
                    if (typeof get(fc.fieldValues, dottedPath) === 'undefined') {
                        set(draftValues, dottedPath, null);
                    }
                }
            });
        });
    }, [fc.fieldValues, notInSync, fc.registeredValues]);

    const _registeredFields = notInSync
        ? []
        : [...Object.keys(fc.hiddenFields), ...Object.keys(fc.disabledFields), ...fc.visibleAndEditableFields];

    const fieldInstanceToSourceDict = [...baseFields, ...Object.values(fieldsByTab).flat()].reduce((prev, curr) => {
        if (curr.props.fieldInstanceIdentifier) {
            prev[curr.props.fieldInstanceIdentifier] = curr.props.source;
            if (curr.props.source.endsWith('Id') && !curr.props.fieldInstanceIdentifier.endsWith('Id')) {
                prev[curr.props.fieldInstanceIdentifier + 'Id'] = curr.props.source;
            }
            if (curr.props.source.endsWith('Ids') && !curr.props.fieldInstanceIdentifier.endsWith('Ids')) {
                prev[curr.props.fieldInstanceIdentifier + 'Ids'] = curr.props.source;
            }
        } else {
            prev[curr.props.source] = curr.props.source;
        }
        return prev;
    }, {} as { [field: string]: string });
    const registeredFields = _registeredFields
        .map((fid) => {
            return fieldInstanceToSourceDict[fid] ?? fid;
        })
        .filter((f) => !f.includes('$'));
    const viewValidations = useAppSelector((state: RootState) =>
        viewName ? state.viewValidations?.[viewName] : undefined,
    );

    const _overriddenViewValidations = useOverriddenViewValidations(viewName);
    const overriddenViewValidations = useMemo(() => {
        return (
            _overriddenViewValidations && [
                ..._overriddenViewValidations,
                ...(viewValidations?.filter((v) => v.__originalView) ?? []),
            ]
        );
    }, [_overriddenViewValidations, viewValidations]);
    const validateErrors = useValidate({
        type: 'error',
        values: valuesToValidateOn,
        resource: resource,
        registeredFields,
        extraValidations: overriddenViewValidations ?? viewValidations,
        adhocVariablesContext: fc.adhocVariablesContext,
    });

    const validateWarnings = useValidation({
        type: 'warn',
        values: valuesToValidateOn,
        resource: resource,
        registeredFields,
        extraValidations: overriddenViewValidations ?? viewValidations,
        adhocVariablesContext: fc.adhocVariablesContext,
    });

    const save2: typeof save = useCallback(
        (values, redirect, onSaveOrFailureCb) => {
            save(values, redirect, onSaveOrFailureCb, fc.visibleAndEditableFields);
        },
        [save, fc],
    );
    return (
        <EditForm2Form
            disallowClickAwayNavigation={disallowClickAwayNavigation}
            _validate={validateErrors}
            _warn={validateWarnings}
            disableFormSaveNotifier={disableFormSaveNotifier}
            valuesForRegisteredFieldsOnly={fc.registeredValues}
            actions={props.actions}
            toolbar={props.toolbar}
            resource={resource}
            optInWriteable={optInWriteable}
            viewName={viewName}
            save={save2}
            form={form}
            id={id}
            taskId={taskId}
            viewConfig={viewConfig}
            initialValues={initialValues}
            fieldValues={fc.fieldValues as { id: string }}
            baseFields={baseFields}
            fieldsByTab={fieldsByTab}
            entities={entities}
            options={options}
            valueSets={valueSets}
            redirect={redirect}
            entityConfigValidations={entityConfigValidations}
            renderLayoutEditor={renderLayoutEditor}
            renderAboveForm={renderAboveForm}
        />
    );
});

// add debug things
const EditForm = React.memo((props: EditForm2Props) => {
    const { id, resource } = props;
    const record = React.useMemo(() => {
        return {
            id,
            entityType: resource,
        };
    }, [id, resource]);
    const viewConfig = useViewConfig();
    if (resource !== viewConfig.views[props.viewName]?.entity) {
        return null;
    }
    return (
        <DisableAllFieldsContextProvider>
            <InjectErrorsToAllFieldsContextProvider>
                <CasetivityViewContextProvider currentViewContext="entity">
                    <EntityExpressionTester
                        type="EDIT"
                        record={record}
                        viewName={props.viewName}
                        formId={props.formId}
                        evaluatedAdhocSPELVariables={props.evaluatedAdhocSPELVariables}
                        entityFormContextRef={props.entityFormContextRef}
                    >
                        {({ renderLayoutEditor, renderEditActionsElem }) => (
                            <>
                                <WithDefaultValues
                                    evaluatedAdhocSPELVariables={props.evaluatedAdhocSPELVariables}
                                    viewName={props.viewName}
                                    id={id}
                                >
                                    {({ defaultValues }) => (
                                        <EditForm2Wrapper
                                            {...props}
                                            defaultValues={defaultValues}
                                            renderLayoutEditor={renderLayoutEditor}
                                            actions={renderEditActionsElem?.(id) ?? props.actions}
                                        />
                                    )}
                                </WithDefaultValues>
                            </>
                        )}
                    </EntityExpressionTester>
                </CasetivityViewContextProvider>
            </InjectErrorsToAllFieldsContextProvider>
        </DisableAllFieldsContextProvider>
    );
});

export default EditForm;
