import { getNextBracketedExpression } from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst/getSelectionExpression';
import evaluateExpression from 'ts-spel-utils/evaluateExpression';
import { identity } from 'fp-ts/lib/function';

export const evaluateFilterString = (
    filterString: string,
    context: {},
    sanitizeResult: (result: string) => string = identity,
): string => {
    const parse = getNextBracketedExpression({
        bracketClosesOn: ']',
        bracketNaivelyOpensOn: '[',
        bracketOpensOn: '$[',
    });
    const evalString = (str: string) =>
        parse(str).fold(str, ({ before, inner, after }) => {
            const result = evaluateExpression(inner, context);
            const sanitizedResult = sanitizeResult(result ? result.toString() : '');
            return `${before}${sanitizedResult}${evalString(after)}`;
        });
    return evalString(filterString);
};

export const applyToFilterString = (filterString: string, items: string[]) => {
    const parse = getNextBracketedExpression({
        bracketClosesOn: ']',
        bracketNaivelyOpensOn: '[',
        bracketOpensOn: '$[',
    });
    let i = 0;
    const evalString = (str: string) =>
        parse(str).fold(str, ({ before, inner, after }) => {
            const currValue = items[i++];
            const result: string = `${before}${currValue}${evalString(after)}`;
            return result;
        });
    return evalString(filterString);
};
