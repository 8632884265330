import React, { useMemo, useContext, useState } from 'react';
import { ViewField, FieldViewField, searchTypes } from 'reducers/ViewConfigType';
import { useForm, useFormContext, FormProvider, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import * as widgetTypes from 'components/generics/utils/widgetTypes';
import {
    TextField,
    FormHelperText,
    TextFieldProps,
    Switch,
    FormGroup,
    FormControlLabel,
    Button,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
} from '@material-ui/core';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { getAttrOfTraversedFieldExprIncludingLinkedX, getRefEntity } from 'components/generics/utils/viewConfigUtils';
import { validDataTypeWidgetCombinations } from 'viewConfigValidation/validWidgetCombinations';
import useViewConfig from 'util/hooks/useViewConfig';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import SearchTypeField from './SearchTypeField';
import SortDirField from './SortDirField';
import { getLabelForFieldExpr } from 'components/generics/utils/viewConfigUtils';
import FieldPath from './FieldPath';
import ControlledJSONSchemaForm from 'react-hook-form-utils/ControlledJsonSchemaForm';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ControlledJSONEditor from 'react-hook-form-utils/ControlledJSONEditor';
import {
    filterConfigurationValueToCurrentFieldType,
    filterConfigurationValueToCurrentType,
} from '../util/filterConfigurationValueToCurrentType';
import LazyJsonSchemaFormConfigField from './JsonSchemaFormConfigField';
import { componentViewPickerContext } from 'fieldFactory/input/components/JsonSchemaForm/customWidgets';
import { getUseSpelModeSelector } from 'util/applicationConfig';
import { getUnpersistedFieldType } from 'fieldFactory/translation/fromEntity/getUnpersistedFieldType';
import RenderUnpersistedRequiredFields from './util/RenderUnpersistedRequiredFields';
import { FormData } from './FormData';

interface DefaultLabelDisplayProps {
    rootEntity: string;
}
const DefaultLabelDisplay: React.FunctionComponent<DefaultLabelDisplayProps> = ({ rootEntity }) => {
    const { watch } = useFormContext<FormData>();
    const fieldPath = watch('fieldPath');
    const viewConfig = useAppSelector((state: RootState) => state.viewConfig);
    const defaultLabel = useMemo(() => {
        try {
            return getLabelForFieldExpr(viewConfig, rootEntity, fieldPath, 'TRAVERSE_PATH');
        } catch (e) {
            return '';
        }
    }, [viewConfig, fieldPath, rootEntity]);

    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    return (
        <TextField
            disabled
            value={defaultLabel}
            InputLabelProps={getInputLabelProps({ shrink: true })}
            label="Label"
            variant={fieldVariant}
            defaultValue={defaultLabel}
            fullWidth
        />
    );
};
interface WidgetTypesProps {
    rootEntity: string;
    isExpression: boolean;
    isCreate?: boolean;
    isListView?: boolean;
    isUnpersisted?: boolean;
}

const LABELS: {
    [key in (typeof widgetTypes)[keyof typeof widgetTypes]]?: string;
} = {
    ADDRESS: 'ADDRESS (Deprecated - do not use)',
    ADDRESS_2: 'ADDRESS',
    BPM_FORM: 'DYNAMIC FORM',
    BPM_FORM_BUILDER: 'DYNAMIC FORM BUILDER',
};
const searchTypesRequiringMultiselect: searchTypes[] = ['IN', 'NOT_IN'];

const WidgetTypes: React.FunctionComponent<WidgetTypesProps> = (props) => {
    const { register, watch } = useFormContext<FormData>();
    const fieldPath = watch('fieldPath');
    const searchType = watch('searchType') as searchTypes;

    const viewConfig = useAppSelector((state: RootState) => state.viewConfig);
    const widgetTypeCodes: (typeof widgetTypes)[keyof typeof widgetTypes][] = useMemo(() => {
        if (props.isExpression) {
            return [widgetTypes.EXPRESSION];
        }
        if (props.isUnpersisted) {
            return [
                'CALENDAR',
                'CALENDARTIME',
                'CHECKBOX',
                'CURRENCY',
                'EMAIL',
                'ENTITY_TYPEAHEAD',
                'FILEUPLOAD',
                'FLOAT',
                'HTML_WYSIWYG',
                'INLINE_MANY',
                'INTEGER',
                'MULTIPLE_ENTITY_TYPEAHEAD',
                'MULTISELECT',
                'NULLABLE_BOOLEAN',
                'SELECT',
                'TEXTAREA',
                'TEXTBOX',
                'TOGGLE',
                'VALUESET_SUGGEST',
            ] as (typeof widgetTypes)[keyof typeof widgetTypes][];
        }
        if (!fieldPath || props.isUnpersisted) {
            return Object.values(widgetTypes);
        }
        if (searchType && searchType === 'NOT_EMPTY') {
            return validDataTypeWidgetCombinations.BOOLEAN;
        }

        try {
            const dt = getAttrOfTraversedFieldExprIncludingLinkedX(
                viewConfig,
                props.rootEntity,
                fieldPath,
                'dataType',
            ) as keyof typeof validDataTypeWidgetCombinations;
            if (dt) {
                if ((dt === 'REFONE' || dt === 'VALUESET') && searchType) {
                    if (searchTypesRequiringMultiselect.includes(searchType)) {
                        if (dt === 'VALUESET') {
                            return validDataTypeWidgetCombinations.VALUESETMANY;
                        }
                        return validDataTypeWidgetCombinations.REFMANYMANY;
                    }
                }
                const allowedWidgetTypes = validDataTypeWidgetCombinations[dt];
                if (dt === 'REFMANYMANY' && allowedWidgetTypes) {
                    // Don't allow the user to pick this right now: only allow it for unpersisted fields.
                    // (Can't save the inline data like that backend currently)
                    return allowedWidgetTypes.filter((wt) => wt !== 'INLINE_MANY');
                }
                return allowedWidgetTypes || Object.values(widgetTypes);
            }
            return Object.values(widgetTypes);
        } catch (e) {
            Object.values(widgetTypes);
        }
    }, [fieldPath, props.rootEntity, viewConfig, props.isExpression, searchType, props.isUnpersisted]);

    const spelMode = useAppSelector(getUseSpelModeSelector);
    const disallowComponentWidgets = props.isListView || spelMode === 'spel2js';
    return (
        <select name="widgetType" ref={register({ required: true })}>
            {(widgetTypeCodes || [])
                .filter((code) => (disallowComponentWidgets ? code !== 'COMPONENT' : code))
                .map((v, i) => {
                    return (
                        <option key={i} value={v}>
                            {LABELS[v] ?? v}
                        </option>
                    );
                })}
        </select>
    );
};

const Label: React.FunctionComponent<{
    defaultValue?: string;
    rootEntity: string;
}> = ({ defaultValue }) => {
    const methods = useFormContext<FormData>();
    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    return (
        <span>
            <Controller
                key={defaultValue}
                InputLabelProps={getInputLabelProps({ shrink: true })}
                label="Label"
                variant={fieldVariant}
                defaultValue={defaultValue}
                as={TextField}
                name="label"
                fullWidth
                control={methods.control as any}
            />
        </span>
    );
};

const Config: React.FunctionComponent<{
    widgetTypeDefaultValue?: string;
    configDefaultValue?: string;
    jsonSchemaDefaultValue?: string;
    rootEntity: string;
    isUnpersistedField?: boolean;
    mode: 'EDIT' | 'SHOW' | 'SORTABLE' | 'CREATE' | 'SEARCH' | 'SPLIT';
}> = ({ widgetTypeDefaultValue, configDefaultValue, jsonSchemaDefaultValue, mode, rootEntity, isUnpersistedField }) => {
    const viewConfig = useViewConfig();
    const methods = useFormContext<FormData & { _jsonschema?: string; _shouldReadSchemasFromField?: boolean }>();
    const widgetType = methods.watch('widgetType');
    console.log({
        widgetType,
        isUnpersistedField,
    });
    const fieldPath = methods.watch('fieldPath');
    const unpersistedEntity = methods.watch('unpersistedEntity');
    const unpersistedValueset = methods.watch('unpersistedValueset');
    const unpersistedIsMany = !!methods.watch('unpersistedIsMany');

    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    const textFieldProps = useMemo((): Partial<TextFieldProps> => {
        return {
            variant: fieldVariant,
            InputLabelProps: getInputLabelProps({}),
        };
    }, [fieldVariant, getInputLabelProps]);
    const readSchemasFromFieldDefaultValue = (() => {
        if (widgetTypeDefaultValue !== 'JSONSCHEMA_FORM') {
            return '';
        }
        if (configDefaultValue) {
            try {
                let parsedConfig = JSON.parse(configDefaultValue);
                return parsedConfig?.['readSchemasFromField'] ?? '';
            } catch (e) {
                return '';
            }
        }
        return '';
    })();
    const readFromField = methods.watch('_shouldReadSchemasFromField', Boolean(readSchemasFromFieldDefaultValue));

    const [foundSchema, filterValue] = useMemo(() => {
        if (isUnpersistedField) {
            try {
                let fieldType: ReturnType<typeof getUnpersistedFieldType> = getUnpersistedFieldType(widgetType, {
                    isValueset: !!unpersistedValueset,
                    isEntity: !!unpersistedEntity,
                    isMany: unpersistedIsMany,
                });
                if (fieldType === 'INLINE_MANYMANY') {
                    fieldType = 'INLINE_MANY';
                }

                return filterConfigurationValueToCurrentFieldType({
                    fieldType,
                    mode,
                });
            } catch (e) {
                console.error(e);
                return [null, (e) => e];
            }
        }

        return filterConfigurationValueToCurrentType({
            rootEntity,
            fieldPath,
            viewConfig,
            widgetType,
            mode,
        });
    }, [
        rootEntity,
        fieldPath,
        viewConfig,
        widgetType,
        mode,
        isUnpersistedField,
        unpersistedEntity,
        unpersistedValueset,
        unpersistedIsMany,
    ]);

    const relatedComponentEntity = useMemo(() => {
        if (widgetType !== 'COMPONENT') {
            return null;
        }
        try {
            return fieldPath ? getRefEntity(viewConfig, rootEntity, fieldPath, true)?.name : rootEntity;
        } catch (e) {
            console.error(e);
            return null;
        }
    }, [rootEntity, fieldPath, widgetType, viewConfig]);

    if (widgetType === 'JSONSCHEMA_FORM') {
        return (
            <>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        {readFromField ? (
                            <Controller
                                InputLabelProps={getInputLabelProps({ shrink: true })}
                                label="Read Schemas From Field"
                                variant={fieldVariant}
                                as={TextField}
                                defaultValue={readSchemasFromFieldDefaultValue}
                                multiline={true}
                                fullWidth
                                name="_readSchemasFromField"
                                control={methods.control as any}
                            />
                        ) : null}
                    </div>
                    <div>
                        <Controller
                            control={methods.control as any}
                            defaultValue={Boolean(readSchemasFromFieldDefaultValue)}
                            name="_shouldReadSchemasFromField"
                            render={({ onChange, value }) => (
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={value}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                    onChange(event.target.checked)
                                                }
                                                inputProps={{ 'aria-label': 'Read Schemas from field' }}
                                            />
                                        }
                                        label="Read schemas from a data field"
                                    />
                                </FormGroup>
                            )}
                        />
                    </div>
                </div>
                {readFromField ? null : (
                    <Controller
                        control={methods.control}
                        defaultValue={jsonSchemaDefaultValue}
                        name="_jsonschema"
                        render={({ onChange, value }) => {
                            const { schema, uiSchema } = (() => {
                                if (!value) {
                                    return { schema: '', uiSchema: '' };
                                }
                                try {
                                    const parsed = JSON.parse(value);
                                    return { schema: parsed?.schema ?? '', uiSchema: parsed?.uiSchema ?? '' };
                                } catch (e) {
                                    console.error(e);
                                    return { schema: '', uiSchema: '' };
                                }
                            })();
                            const handleChange = (newSchema: string, newUiSchema: string) => {
                                onChange(
                                    JSON.stringify({
                                        schema: newSchema,
                                        uiSchema: newUiSchema,
                                    }),
                                );
                            };
                            return (
                                <LazyJsonSchemaFormConfigField
                                    textFieldProps={textFieldProps}
                                    schema={schema}
                                    uiSchema={uiSchema}
                                    onChange={handleChange}
                                />
                            );
                        }}
                    />
                )}
            </>
        );
    } else {
        if (foundSchema) {
            return (
                <componentViewPickerContext.Provider value={relatedComponentEntity}>
                    <ControlledJSONSchemaForm
                        asString
                        name="config"
                        defaultValue={configDefaultValue ?? '{}'}
                        schema={foundSchema.schema}
                        uiSchema={foundSchema.uiSchema}
                    />
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Inspect or edit field configuration in JSON</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: 'block' }}>
                            <div>
                                <ControlledJSONEditor
                                    name="config"
                                    asString
                                    filterValue={filterValue}
                                    defaultValue={configDefaultValue ?? '{}'}
                                />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </componentViewPickerContext.Provider>
            );
        }
    }
    return (
        <Controller
            InputLabelProps={getInputLabelProps({ shrink: true })}
            label="Config"
            variant={fieldVariant}
            as={TextField}
            defaultValue={configDefaultValue}
            multiline={true}
            fullWidth
            name="config"
            control={methods.control as any}
        />
    );
};

interface AddFieldProps {
    rootEntity: string;
    includeSearchType?: boolean;
    includeSortAttributes?: boolean;
    onAdd: (field: ViewField) => void;
    initialValues?: Partial<FormData>;
    mode?: 'EDIT' | 'SHOW';
    isCreate?: boolean;
    requireExpressionFieldId?: boolean;
    allowCalcs?: boolean;
    isSplit?: boolean;
}
const AddField: React.FC<AddFieldProps> = (props) => {
    const { mode = 'EDIT', isSplit, isCreate } = props;
    const initialIsExpression = props.initialValues && props.initialValues['widgetType'] === 'EXPRESSION';

    const [typeOfField, setTypeOfField] = useState<'expression' | 'data' | 'unpersisted'>(
        initialIsExpression ? 'expression' : props.initialValues?.unpersistedField ? 'unpersisted' : 'data',
    );

    const [overrideIdCheckboxChecked, setOverrideIdCheckboxChecked] = useState(
        Boolean(props.initialValues?.overrideId),
    );
    const [overrideLabelChecked, setOverrideLabelChecked] = useState(Boolean(props.initialValues?.label));
    const defaultValues = useMemo(() => {
        if (props.initialValues) {
            if (initialIsExpression) {
                const { fieldPath, ...rest } = props.initialValues;
                return {
                    ...rest,
                    fieldPath: '',
                    expressionFieldPath: fieldPath,
                };
            }
            return props.initialValues;
        }
        return initialIsExpression ? { expressionFieldPath: '' } : { fieldPath: '' };
    }, [props.initialValues, initialIsExpression]);

    const methods = useForm<FormData>({
        defaultValues,
    });

    const currentWidgetType = methods.watch('widgetType');
    const config = methods.watch('config');

    const noComponentViewError = useMemo(() => {
        if (currentWidgetType !== 'COMPONENT' || !config) {
            return null;
        }
        try {
            return !JSON.parse(config).component;
        } catch (e) {
            return null;
        }
    }, [currentWidgetType, config]);

    const viewConfig = useViewConfig();
    const onSubmit = (data: FormData & { _jsonschema?: string; _shouldReadSchemasFromField?: boolean }) => {
        if (noComponentViewError) {
            return;
        }
        const config = (() => {
            if (data.widgetType === 'JSONSCHEMA_FORM') {
                if (data['_shouldReadSchemasFromField']) {
                    return JSON.stringify({
                        readSchemasFromField: data['_readSchemasFromField'],
                    });
                }
                return data['_jsonschema'];
            }
            const [foundSchema, filterValue] = filterConfigurationValueToCurrentType({
                rootEntity: props.rootEntity,
                widgetType: data.widgetType,
                fieldPath: data.fieldPath,
                mode: isSplit
                    ? 'SPLIT'
                    : isCreate
                    ? 'CREATE'
                    : props.includeSortAttributes
                    ? 'SORTABLE'
                    : props.includeSearchType
                    ? 'SEARCH'
                    : mode,
                viewConfig,
            });
            if (foundSchema) {
                try {
                    const parsedConfig = JSON.parse(data.config);
                    const filteredConfig = filterValue(parsedConfig);
                    return JSON.stringify(filteredConfig);
                } catch (e) {}
            }
            return data.config || undefined;
        })();
        const newField = {
            widgetType: data.widgetType,
            entity:
                typeOfField === 'expression'
                    ? undefined
                    : data.fieldPath?.includes('.')
                    ? getRefEntity(
                          viewConfig,
                          props.rootEntity,
                          data.fieldPath.slice(0, data.fieldPath.lastIndexOf('.')),
                          true,
                      )?.name
                    : props.rootEntity,
            config,
        } as FieldViewField & {
            overrideId?: string;
            unpersistedField?: string;
            unpersistedEntity?: string;
            unpersistedValueset?: string;
            unpersistedIsMany?: boolean;
        };

        if (typeOfField === 'expression') {
            newField.field = data.expressionFieldPath;
        } else if (typeOfField === 'data') {
            newField.field = data.fieldPath;
        } else {
            newField.unpersistedField = data.unpersistedField;
            if (data.unpersistedEntity) {
                newField.unpersistedEntity = data.unpersistedEntity;
            }
            if (data.unpersistedValueset) {
                newField.unpersistedValueset = data.unpersistedValueset;
            }
            if (data.unpersistedIsMany) {
                newField.unpersistedIsMany = data.unpersistedIsMany;
            }
        }
        if ((overrideLabelChecked || typeOfField === 'unpersisted') && data.label) {
            newField.label = data.label;
        }
        if ((overrideIdCheckboxChecked || data.widgetType === 'COMPONENT') && data.overrideId) {
            newField.overrideId = data.overrideId;
        }
        if (data.searchType) {
            newField.searchType = data.searchType as searchTypes;
        }
        if (data.sortOrder || data.sortOrder === 0) {
            newField.sortOrder = data.sortOrder;
        }
        if (data.sortDir) {
            newField.sortDir = data.sortDir;
        }

        props.onAdd(newField);
    };
    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);

    const WidgetTypesElem = (
        <>
            <WidgetTypes
                isListView={props.includeSortAttributes || props.includeSearchType}
                isCreate={isCreate}
                isExpression={typeOfField === 'expression'}
                rootEntity={props.rootEntity}
                isUnpersisted={typeOfField === 'unpersisted'}
            />
            {/* errors will return when field validation fails  */}
            {methods.errors.widgetType && <FormHelperText error>This field is required</FormHelperText>}
        </>
    );

    return (
        <div>
            <br />
            <label htmlFor="isExpression">Expression Field?</label>
            <input
                type="checkbox"
                id="isExpression"
                name="isExpression"
                checked={typeOfField === 'expression'}
                onChange={(event) => setTypeOfField(event.target.checked ? 'expression' : 'data')}
            />
            <label htmlFor="isExpression">Unpersisted field?</label>
            <input
                type="checkbox"
                id="unpersisted"
                name="unpersisted"
                checked={typeOfField === 'unpersisted'}
                onChange={(event) => setTypeOfField(event.target.checked ? 'unpersisted' : 'data')}
            />
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    {/* register your input into the hook by invoking the "register" function */}
                    <div
                        style={{
                            backgroundColor: 'whitesmoke',
                            border: '1px solid gray',
                            padding: '.5em 1em',
                            margin: '1em 0',
                        }}
                    >
                        {typeOfField === 'unpersisted' ? (
                            <div>
                                <Controller
                                    margin="normal"
                                    InputLabelProps={getInputLabelProps({ shrink: true })}
                                    label="Unpersisted field id"
                                    variant={fieldVariant}
                                    as={TextField}
                                    defaultValue={defaultValues?.['unpersistedField'] ?? ''}
                                    fullWidth
                                    name="unpersistedField"
                                    rules={{
                                        validate: (value) => {
                                            if (!value) {
                                                return 'An unpersisted field Id is required here';
                                            }
                                            return (
                                                !value ||
                                                value.startsWith('_') ||
                                                "Unpersisted field's Id must start with '_'"
                                            );
                                        },
                                    }}
                                    control={methods.control as any}
                                />
                                <ErrorMessage
                                    render={({ message }) => <FormHelperText error>{message}</FormHelperText>}
                                    errors={methods.errors}
                                    name="unpersistedField"
                                />
                            </div>
                        ) : typeOfField === 'expression' ? (
                            <>
                                <Controller
                                    margin="normal"
                                    InputLabelProps={getInputLabelProps({ shrink: true })}
                                    label="Expression field id"
                                    variant={fieldVariant}
                                    as={TextField}
                                    defaultValue={defaultValues && defaultValues['expressionFieldPath']}
                                    fullWidth
                                    name="expressionFieldPath"
                                    rules={{
                                        validate: (value) => {
                                            if (props.requireExpressionFieldId && !value) {
                                                return 'An Expression Id is required here';
                                            }
                                            return (
                                                !value || value.startsWith('$') || "Expression Id must start with '$'"
                                            );
                                        },
                                    }}
                                    control={methods.control as any}
                                />
                                <ErrorMessage
                                    render={({ message }) => <FormHelperText error>{message}</FormHelperText>}
                                    errors={methods.errors}
                                    name="expressionFieldPath"
                                />
                            </>
                        ) : (
                            <div>
                                <FieldPath
                                    required={currentWidgetType !== 'COMPONENT'}
                                    allowPropertiesOnManys={Boolean(props.includeSearchType)}
                                    defaultValue={defaultValues && defaultValues['fieldPath']}
                                    resource={props.rootEntity}
                                    depth={3}
                                    allowCalcs={props.allowCalcs}
                                />
                                <ErrorMessage
                                    render={({ message }) => <FormHelperText error>{message}</FormHelperText>}
                                    errors={methods.errors}
                                    name="fieldPath"
                                />
                                {currentWidgetType === 'COMPONENT' ? null : (
                                    <>
                                        <label htmlFor="overrideIdCheckboxChecked">Override Id?</label>
                                        <input
                                            type="checkbox"
                                            id="overrideIdCheckboxChecked"
                                            name="overrideIdCheckboxChecked"
                                            checked={overrideIdCheckboxChecked}
                                            onChange={(event) => setOverrideIdCheckboxChecked(event.target.checked)}
                                        />
                                    </>
                                )}
                                {overrideIdCheckboxChecked || currentWidgetType === 'COMPONENT' ? (
                                    <>
                                        <Controller
                                            InputLabelProps={getInputLabelProps({ shrink: true })}
                                            label="Override Id"
                                            variant={fieldVariant}
                                            margin="normal"
                                            as={TextField}
                                            defaultValue={defaultValues && defaultValues['overrideId']}
                                            fullWidth
                                            name="overrideId"
                                            control={methods.control as any}
                                            rules={
                                                currentWidgetType === 'COMPONENT'
                                                    ? {
                                                          pattern: {
                                                              value: /^\$/,
                                                              message: 'COMPONENT widget ids must begin with $',
                                                          },
                                                          required: 'COMPONENT widgets need to be provided custom ids',
                                                      }
                                                    : undefined
                                            }
                                        />
                                        <ErrorMessage
                                            render={({ message }) => <FormHelperText error>{message}</FormHelperText>}
                                            errors={methods.errors}
                                            name="overrideId"
                                        />
                                    </>
                                ) : null}
                            </div>
                        )}
                        {methods.errors.fieldPath && <FormHelperText error>This field is required</FormHelperText>}
                        {/* include validation with required or other standard HTML validation rules */}
                        {WidgetTypesElem}
                        {typeOfField === 'unpersisted' && (
                            <>
                                <RenderUnpersistedRequiredFields defaultValues={defaultValues} />
                                <div style={{ height: '16px' }} />
                                {typeOfField === 'unpersisted' && (
                                    <Label
                                        rootEntity={props.rootEntity}
                                        defaultValue={defaultValues?.['label'] ?? ''}
                                    />
                                )}
                                <div style={{ height: '.5em' }} />
                            </>
                        )}
                    </div>

                    <div style={{ marginBottom: '10px' }} />
                    {typeOfField !== 'unpersisted' && (
                        <>
                            <label htmlFor="overrideLabelCheckboxChecked">Override Label?</label>
                            <input
                                type="checkbox"
                                id="overrideLabelCheckboxChecked"
                                name="overrideLabelCheckboxChecked"
                                checked={overrideLabelChecked}
                                onChange={(event) => setOverrideLabelChecked(event.target.checked)}
                            />
                            <div style={{ marginTop: '.5em' }}>
                                {overrideLabelChecked ? (
                                    <Label
                                        rootEntity={props.rootEntity}
                                        defaultValue={defaultValues?.['label'] ?? ''}
                                    />
                                ) : (
                                    <DefaultLabelDisplay rootEntity={props.rootEntity} />
                                )}
                            </div>
                        </>
                    )}
                    {props.includeSearchType ? <div style={{ marginBottom: '10px' }} /> : null}
                    {props.includeSearchType ? <SearchTypeField /> : null}
                    {props.includeSortAttributes ? <div style={{ marginBottom: '10px' }} /> : null}
                    {props.includeSortAttributes ? <SortDirField /> : null}
                    {props.includeSortAttributes ? (
                        <Controller
                            InputLabelProps={getInputLabelProps({ shrink: true })}
                            label="SortOrder"
                            variant={fieldVariant}
                            margin="normal"
                            type="number"
                            as={TextField}
                            defaultValue={defaultValues && defaultValues['sortOrder']}
                            fullWidth
                            name="sortOrder"
                            control={methods.control as any}
                        />
                    ) : null}
                    <div style={{ marginBottom: '10px' }} />
                    <Config
                        mode={
                            isSplit
                                ? 'SPLIT'
                                : isCreate
                                ? 'CREATE'
                                : props.includeSortAttributes
                                ? 'SORTABLE'
                                : props.includeSearchType
                                ? 'SEARCH'
                                : mode
                        }
                        isUnpersistedField={typeOfField === 'unpersisted'}
                        widgetTypeDefaultValue={defaultValues?.['widgetType']}
                        configDefaultValue={defaultValues?.['config']}
                        rootEntity={props.rootEntity}
                        jsonSchemaDefaultValue={
                            defaultValues?.['widgetType'] === 'JSONSCHEMA_FORM' ? defaultValues?.['config'] : undefined
                        }
                    />
                    {noComponentViewError && <FormHelperText error>A COMPONENT view is required.</FormHelperText>}
                    <div style={{ marginBottom: '5px' }} />
                    <Button disabled={noComponentViewError} variant="contained" color="primary" type="submit">
                        Submit
                    </Button>
                </form>
            </FormProvider>
        </div>
    );
};

export default AddField;
