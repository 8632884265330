import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { formContext } from 'components/generics/form/EntityFormContext';
import { formContext as showFormContext } from 'components/generics/form/EntityFormContext/Show';
import formTypeContext from 'components/generics/form/formTypeContext';
import useViewConfig from 'util/hooks/useViewConfig';
import useEntities from 'util/hooks/useEntities';
import expandMinimalDataFromDataPaths from 'components/generics/form/EntityFormContext/util/expandMinimalDataFromPaths';
import useCurrentFormContext from 'components/generics/form/EntityFormContext/hooks/useCurrentFormContext';
import deepMerge from '@fastify/deepmerge';
import { useBackrefProperties } from 'components/generics/form/EntityFormContext/util/createBackrefSelector';
import {
    PathTrie,
    setNullOnMissingPaths,
} from 'components/generics/form/EntityFormContext/util/createPathTreeFromGraph';
import { CachingEvaluator } from 'expressions/CachingEvaluator/Evaluator';
import useExpressionEval from 'expressions/Provider/hooks/useExpressionEval';
import useEvalContext from 'expressions/Provider/hooks/useEvalContext';
import { clearCachesForEvaluator, useEvalFn } from 'expressions/Provider/hooks/useKeyCachingEval';
import flatten from 'flat';
import { getNextBracketedExpression } from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst/getSelectionExpression';
import { purifyHtmlMoreStrict } from 'fieldFactory/display/components/HtmlDisplay';
import { entityNullInitializeValues } from 'expressions/formValidation';
import {
    expandComponentFields,
    getAllFieldEntriesFromView,
    getDataTypeForFieldExpr,
    getValueSetForFieldExpr,
    isFieldViewField,
} from 'components/generics/utils/viewConfigUtils';
import ViewConfig, { FieldViewField } from 'reducers/ViewConfigType';
import { Option, none, fromNullable, fromPredicate } from 'fp-ts/lib/Option';
import memoizeOne from 'memoize-one';
import unwrapAlongtrie from './unwrapAlongTrie';

const getValueset1FieldsInView = (viewConfig: ViewConfig, viewName: string): { [field: string]: string } => {
    const fields = expandComponentFields(
        viewConfig,
        getAllFieldEntriesFromView(viewConfig, viewName),
        viewConfig.views[viewName].entity,
        {
            rebaseExpressionsWithinFields: false,
            replaceXmanyWithMultiCard: false,
        },
    )
        .expandedFieldsByRow.flat()
        .map((t) => t[1])
        .filter((f) => isFieldViewField(f)) as FieldViewField[];

    return Object.fromEntries(
        fields
            .filter((f) => {
                const dataType = getDataTypeForFieldExpr(viewConfig, f.entity, f.field, 'POP_LAST');
                return dataType === 'VALUESET';
            })
            .map((f) => [f.field, getValueSetForFieldExpr(viewConfig, f.entity, f.field, 'POP_LAST')]),
    );
};

function hasCycle(obj, visited = new Set()) {
    if (obj && typeof obj === 'object') {
        if (visited.has(obj)) {
            return true;
        }

        visited.add(obj);

        if (Array.isArray(obj)) {
            // If it's an array, iterate over its elements
            for (const element of obj) {
                if (hasCycle(element, visited)) {
                    return true;
                }
            }
        } else {
            // If it's an object, iterate over its keys
            for (const key in obj) {
                if (hasCycle(obj[key], visited)) {
                    return true;
                }
            }
        }

        visited.delete(obj); // Clean up visited after traversal
    }
    return false;
}

function replaceByClonedSource(options) {
    const clone = options.clone;
    return function (target, source) {
        return clone(source);
    };
}
const mergeData = deepMerge({
    mergeArray: replaceByClonedSource,
    all: true,
});
const useMinimalValuesInEntityContextFromExpansions = (paths: PathTrie) => {
    const sfc = useContext(showFormContext);
    const efc = useContext(formContext);
    const currentFormTypeContext = useContext(formTypeContext);
    const fc = currentFormTypeContext === 'SHOW' ? sfc : efc;
    const viewConfig = useViewConfig();
    const entities = useEntities();
    const baseEntity = viewConfig.views[fc.viewName]?.entity;
    const id = fc === efc ? efc.initialValues['id'] : fc.fieldValues['id'];
    const dataPaths = useMemo(() => {
        return Object.keys(flatten(paths));
    }, [paths]);
    const values = useMemo(
        () => expandMinimalDataFromDataPaths(viewConfig, baseEntity, id, entities, dataPaths, paths),
        [id, baseEntity, entities, dataPaths, viewConfig, paths],
    );
    return values;
};

interface AdhocEvalInEntityContextContext {
    getValueLive: (expression: string) => Option<unknown>;
    subscribeLive: (
        expression: string,
        cb: (result: unknown) => void,
        defaultOnException: unknown,
    ) => {
        currentValue?: unknown;
        unsubscribe: () => void;
    };
    getValueDB: (expression: string) => Option<unknown>;
    subscribeDB: (
        expression: string,
        cb: (result: unknown) => void,
        defaultOnException: unknown,
    ) => {
        currentValue?: unknown;
        unsubscribe: () => void;
    };
}

const adhocEvalInEntityContext = React.createContext<AdhocEvalInEntityContextContext>({
    getValueLive: () => none,
    subscribeLive: () => {
        // no longer throw these, because I need to run hooks outside of entity form contexts and have it fallback to flowable version

        // throw new Error('No Provider');
        return {
            unsubscribe: () => {},
        };
    },
    getValueDB: () => none,
    subscribeDB: () => {
        // throw new Error('No Provider');
        return {
            unsubscribe: () => {},
        };
    },
});

const useSubscribe = (mode: 'live' | 'db') => {
    const ec = useExpressionEval();
    const viewConfig = useViewConfig();
    const fc = useCurrentFormContext();
    const backrefProperties = useBackrefProperties(fc['initialValues'] ?? null);
    const _context = useEvalContext();
    const context = useMemo(() => {
        return {
            ..._context,
            _user: viewConfig.user,
            ...fc.adhocVariablesContext,
            getCurrentViewType: () => viewConfig.views[fc.viewName]?.viewType ?? null,
            getBackref: () => backrefProperties ?? null,
        };
    }, [_context, fc.adhocVariablesContext, backrefProperties, viewConfig, fc.viewName]);

    const evalFn = useEvalFn(ec);
    const listenersRef = useRef<{
        [expression: string]: ((value: unknown) => void)[];
    }>({});

    const evaluator = useMemo(
        () => new CachingEvaluator([], evalFn, _context, 'deepeql', (expression) => (values) => null),
        // we will update the context ourselves. This is just to start it off.
        [evalFn], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const expansionsTrieRef = useRef<PathTrie>({});

    const evaluatorRef = useRef(evaluator);

    const [rerenderKey, rerender] = useReducer((state) => state + 1, 1);
    useMemo(() => {
        // this could be smarter. We could clear only the caches we need to - not _every_ time the context changes.
        const doVoid = (val: any) => {};
        doVoid(rerenderKey); // doing this to make linter happy that we used the variable, and not just disable the linter on that line
        clearCachesForEvaluator(evaluatorRef.current, context);
    }, [context, rerenderKey]);

    const subscribe = useCallback(
        (
            expression: string,
            cb: (result: unknown) => void,
            defaultOnException: unknown,
        ): {
            currentValue?: unknown;
            unsubscribe: () => void;
        } => {
            if (!listenersRef.current[expression]) {
                listenersRef.current[expression] = [];
                // We also need to accExpressions in KeyCachingEvaluator
                evaluatorRef.current.addExpressions([expression], evalFn, _context, (expression) => {
                    return (values) => defaultOnException;
                });
                expansionsTrieRef.current = mergeData(
                    expansionsTrieRef.current,
                    ...Object.values(evaluatorRef.current.state).map((ce) => ce.dataPathTrie),
                );
            }
            listenersRef.current[expression].push(cb);
            rerender();
            return {
                currentValue: evaluatorRef.current.state[expression]?.cachedResult.toUndefined(),
                unsubscribe: () => {
                    const index = listenersRef.current[expression].indexOf(cb);
                    listenersRef.current[expression].splice(index, 1);
                },
            };
        },
        [evalFn], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const getValue = useCallback((expression: string) => {
        return fromNullable(evaluatorRef.current.state[expression]).chain((evaluator) => evaluator.cachedResult);
    }, []);
    const valueset1FieldsInView = useMemo(() => {
        return viewConfig.views[fc.viewName] ? getValueset1FieldsInView(viewConfig, fc.viewName) : {};
    }, [viewConfig, fc.viewName]);

    const entities = useEntities();
    const entityType = viewConfig.views[fc.viewName].entity;

    const fieldValues = useMemo(
        // I wonder if I can just do the valueset field setting _in_ the formContextEvaluator.
        // It just uses the expressions and expanded fields to figure it out so no reason not to do it there...
        () =>
            entityNullInitializeValues(
                // we patch onto the base record so we don't accidentally null anything out when we merge the result of this onto dbValues
                // (e.g. we don't want 'title' to be null if not included in fc.fieldValues)
                Object.assign({}, entities[entityType]?.[fc.fieldValues['id']] ?? {}, fc.fieldValues),
                expansionsTrieRef.current,
                valueset1FieldsInView,
                entities,
            ),
        [fc.fieldValues, valueset1FieldsInView, entities, entityType],
    );

    const dbValues = useMinimalValuesInEntityContextFromExpansions(expansionsTrieRef.current);

    const mergedData = useMemo(() => {
        if (mode === 'db') {
            return dbValues;
        }
        // check entityPreprocess for both useLiveMode and not, that it sets e.g. stageCode for non-live

        // mergeData will stackoverflow if there's a cycle, so let's expand naively along the expansionsTrieRef given.
        // See tests for 'unwrapAlongTrie' for the general idea.
        if (hasCycle(fieldValues)) {
            // We are being a bit slower and more conservative (expanding more data unnecessarily) by using unwrapAlongTrie
            // instead of 'createPathTree' which literally only expands what's in the PathTrie given.
            // unwrapAlongTrie expands references, but also everything directly on the records we include on the way.

            // Because we might have things like foo['bar'], we lean towards this ('bar' won't be in the expansionsTrieRef)
            // createPathTree is obviously far superior for performance, but I haven't benchmarked it.
            const unwrappedFieldValues = unwrapAlongtrie(fieldValues, expansionsTrieRef.current);
            // const unwrappedFieldValues = createPathTree(fieldValues, expansionsTrieRef.current); //<- faster but stricter
            const res = mergeData(dbValues, unwrappedFieldValues);
            return res;
        }

        const res = mergeData(dbValues, fieldValues);
        return res;
    }, [dbValues, fieldValues, mode]);

    const memoizedSetNullOnMissingPaths = useMemo(() => memoizeOne(setNullOnMissingPaths), []);
    useEffect(() => {
        const data = memoizedSetNullOnMissingPaths(mergedData, expansionsTrieRef.current);
        const results = evaluatorRef.current.evaluateAll(data);

        evaluatorRef.current.getChangedExpressions().forEach((expression) => {
            const result = results[expression];
            listenersRef.current[expression].forEach((cb) => cb(result));
        });
    }, [mergedData, context, rerenderKey, memoizedSetNullOnMissingPaths]);

    return { subscribe, getValue };
};

export const AdhocEvalInEntityContextProvider: React.FC<{}> = (props) => {
    const { subscribe: subscribeDB, getValue: getValueDB } = useSubscribe('db');
    const { subscribe: subscribeLive, getValue: getValueLive } = useSubscribe('live');
    const value = useMemo(
        () => ({
            subscribeLive,
            getValueLive,
            subscribeDB,
            getValueDB,
        }),
        [subscribeLive, subscribeDB, getValueLive, getValueDB],
    );
    return <adhocEvalInEntityContext.Provider value={value}>{props.children}</adhocEvalInEntityContext.Provider>;
};

const DEFAULT_VALUE_PLACEHOLDER = '_DEFAULT_';

export const useAdhocEvalInEntityContext = (
    expression: string | boolean,
    mode: 'live' | 'db',
    defaultValue: unknown, // returns this after an error, or on first call before we render with the resolved value.
) => {
    const { subscribeLive, subscribeDB, getValueLive, getValueDB } = useContext(adhocEvalInEntityContext);
    const subscribe = mode === 'db' ? subscribeDB : subscribeLive;
    const getValue = mode === 'db' ? getValueDB : getValueLive;
    const [result, setResult] = useState<unknown>(() =>
        fromPredicate((exp): exp is string => typeof exp === 'string')(expression)
            .chain((exp) => getValue(exp))
            .getOrElse(DEFAULT_VALUE_PLACEHOLDER),
    );
    useEffect(() => {
        if (typeof expression === 'boolean' || expression === null || !expression.trim()) {
            return;
        }
        const { unsubscribe, currentValue } = subscribe(expression, setResult, DEFAULT_VALUE_PLACEHOLDER);

        return unsubscribe;
    }, [expression, subscribe, mode, defaultValue]);

    if (typeof expression === 'boolean' || expression === null) {
        return expression;
    }
    if (!expression.trim()) {
        // if expression is empty string, treat it like an error
        return defaultValue;
    }
    if (result === DEFAULT_VALUE_PLACEHOLDER) {
        return defaultValue;
    }
    return result;
};

const parse = getNextBracketedExpression({
    bracketClosesOn: ']',
    bracketNaivelyOpensOn: '[',
    bracketOpensOn: '$[',
});

// returns null if not all results present
const templateWithResults = (
    templateString: string,
    results: {
        [expression: string]: string;
    },
) => {
    let somethingMissing = false;
    const evalString = (str: string) => {
        return parse(str).fold(str, ({ before, inner, after }) => {
            const result = results[inner];
            if (typeof result === 'undefined') {
                somethingMissing = true;
            }
            return `${before}${result}${evalString(after)}`;
        });
    };
    const result = evalString(templateString);
    if (somethingMissing) {
        return null;
    }
    return result;
};

export const useEvaluateAdhocTemplateInEntityContext = (
    templateString: string,
    mode: 'live' | 'db',
    sanitizeResult: (value: string) => string = purifyHtmlMoreStrict,
) => {
    const { subscribeLive, subscribeDB, getValueLive, getValueDB } = useContext(adhocEvalInEntityContext);
    const subscribe = mode === 'db' ? subscribeDB : subscribeLive;
    const getValue = mode === 'db' ? getValueDB : getValueLive;
    const [, rerender] = useReducer((state) => state + 1, 1);

    const [expressions, indexesByExpression] = useMemo(() => {
        const exps: string[] = [];
        const indexesByExp: {
            [exp: string]: number[];
        } = {};
        let ix = 0;
        const accString = (str: string) =>
            parse(str).fold(str, ({ before, inner, after }) => {
                exps.push(inner);
                if (!indexesByExp[inner]) {
                    indexesByExp[inner] = [];
                }
                indexesByExp[inner].push(ix++);
                accString(after);
                return '';
            });
        accString(templateString);
        return [exps, indexesByExp] as const;
    }, [templateString]);

    const resultsRef = useRef<{ [expression: string]: string }>({});
    useMemo(() => {
        expressions.forEach((expression) => {
            getValue(expression).fold(null, (result) => {
                resultsRef.current[expression] = result?.toString() ?? '';
            });
        });
    }, [expressions, getValue]);

    useEffect(() => {
        const unsubscribes: (() => void)[] = [];
        expressions.forEach((expression) => {
            const { currentValue, unsubscribe } = subscribe(
                expression,
                (result) => {
                    resultsRef.current[expression] = sanitizeResult(result ? result.toString() : '');
                    rerender();
                },
                '', // default to empty string if an exception occurs.
            );
            unsubscribes.push(unsubscribe);
        });
        return () => {
            unsubscribes.forEach((unsub) => unsub());
        };
    }, [expressions, subscribe, sanitizeResult]);

    const templatedByResults = templateWithResults(templateString, resultsRef.current);
    return templatedByResults;
};
