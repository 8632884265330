import React from 'react';
import {
    GenericShow,
    GenericList,
    GenericEdit,
    GenericCreate,
    GenericPossibleMatchView,
    GenericSpecificMatchView,
} from '../components/generics';

import { Helmet } from 'react-helmet';
import ViewConfig, { View } from '../reducers/ViewConfigType';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
import { EvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';

export type ViewComponentType = React.ComponentType<{ location: any; match: any }> | null;

const useResourceDisplay = (resource: string, plural: boolean) => {
    const resourceDisplay = useAppSelector((state: RootState) => {
        return fromNullable(state.viewConfig.entities[resource])
            .mapNullable((r) => (plural ? r.displayNamePlural : r.displayName))
            .getOrElse(resource);
    });
    return resourceDisplay;
};
const useEntityTitle = (id: string, resource: string, fallbackValue: null | string | undefined) => {
    const title = useAppSelector((state: RootState) => {
        return fromNullable(state.admin.entities[resource])
            .mapNullable((eb) => eb[id])
            .mapNullable((e) => e.title)
            .getOrElse(fallbackValue);
    });
    return title;
};
const renderWithTitle = (BaseElement: JSX.Element, title: string) => (
    <React.Fragment>
        <EvaluateFormattedMessage>
            {({ evaluateFormattedMessage }) => {
                return (
                    <Helmet>
                        <title>{evaluateFormattedMessage(title)}</title>
                    </Helmet>
                );
            }}
        </EvaluateFormattedMessage>
        {BaseElement}
    </React.Fragment>
);
const withDocumentTitle = (type: 'Edit' | 'View' | 'List' | 'Create' | 'Matches' | 'Merge') => {
    if (type === 'Edit' || type === 'View') {
        return (BaseComponent) => (props) => {
            const id = props.match.params.id;
            const resource = props.resource;
            const title = useEntityTitle(id, resource, 'Casetivity');
            const resourceDisplay = useResourceDisplay(resource, false);
            return renderWithTitle(<BaseComponent {...props} id={id} />, `${type} ${resourceDisplay}: ${title}`);
        };
    }
    if (type === 'List') {
        return (BaseComponent) => (props) => {
            const resourceDisplay = useResourceDisplay(props.resource, true);
            return renderWithTitle(<BaseComponent {...props} />, `Search ${resourceDisplay}`);
        };
    }
    if (type === 'Create') {
        return (BaseComponent) => (props) => {
            const resourceDisplay = useResourceDisplay(props.resource, false);
            return renderWithTitle(<BaseComponent {...props} />, `Create ${resourceDisplay}`);
        };
    }
    if (type === 'Matches') {
        return (BaseComponent) => (props) => {
            const title = useEntityTitle(props.match.params.id, props.resource, 'record');
            const resourceDisplay = useResourceDisplay(props.resource, true);
            return renderWithTitle(<BaseComponent {...props} />, `Matching ${resourceDisplay} for ${title}`);
        };
    }
    if (type === 'Merge') {
        return (BaseComponent) => (props) => {
            return renderWithTitle(<BaseComponent {...props} />, `Resolve Possible Match`);
        };
    }
    return (i) => i;
};

const components = {
    EDIT: withDocumentTitle('Edit')(GenericEdit),
    SHOW: withDocumentTitle('View')(GenericShow),
    LIST: withDocumentTitle('List')(GenericList),
    CREATE: withDocumentTitle('Create')(GenericCreate),
    MATCH: withDocumentTitle('Matches')(GenericPossibleMatchView),
    MERGE: withDocumentTitle('Merge')(GenericSpecificMatchView),
};

export const getViewComponent = (view: View) => {
    const ViewComponent = components[view.viewType];
    return (props) => {
        if (!ViewComponent) {
            console.error('Bad viewType: ' + view.viewType + ' on view ' + view.name);
            return null;
        }
        return <ViewComponent {...props} resource={view.entity} createMobileAppBar={true} viewName={view.name} />;
    };
};

/*
Background info:
    1. We are dynamically creating components with viewName set below.
    2. Each time search/pagination etc parameters change for list view, a push is dispatched;
The problem with 1. and 2:
    The route sees a new component and we get components unmounting/remounting between search changes.
    Besides being unecessary/bad for performance, this means we can't deduce much from a component mounting/unmounting.
    (I would like to know that when a List view is mounted/unmounted,
        we can start with a fresh filter, and not have to persist the old one.)

In order to keep referential integrity, we will memoize
    components in the dictionary below, indexing by defaultListView value.

*/
/*
    Memoization also added for Show, Edit, Create to prevent Form values + open
        tabs from being lost due to window resize, push events, etc.
*/

const throwDefaultViewNotFound = ({
    type,
    entityName,
    viewName,
}: {
    type: 'LIST' | 'CREATE' | 'EDIT' | 'SHOW';
    entityName: string;
    viewName: string;
}) => {
    throw new Error(
        `The default ${type} view for the entity "${entityName}" is "${viewName}". However this view was not found. Either create a viewDef with the name "${viewName}", or change the default ${type} view on ${entityName} to a different viewDef.`,
    );
};

export interface ResourceRouteDescription {
    entityIndex: string; // _actually_ the entity 'name'
    name: string; // actually the entityPlural
    list?: ViewComponentType | null;
    edit?: ViewComponentType | null;
    create?: ViewComponentType | null;
    show?: ViewComponentType | null;
    possibleMatchView?: ViewComponentType | null;
    specificMatchView?: ViewComponentType | null;
}
const getResourceStateFromViewConfig = (viewConfig: ViewConfig): ResourceRouteDescription[] =>
    Object.values(viewConfig.entities ? viewConfig.entities : []).map((entityDef) => {
        const entityName = entityDef.name;
        const entityPlural = entityDef.name;
        let List: ViewComponentType = null;
        let Edit: ViewComponentType = null;
        let Create: ViewComponentType = null;
        let Show: ViewComponentType = null;
        let PossibleMatchView: ViewComponentType = null;
        let SpecificMatchView: ViewComponentType = null;
        if (entityDef?.defaultViews?.LIST) {
            const defaultListView = entityDef.defaultViews.LIST.name;
            const view = viewConfig.views[defaultListView];
            if (!view) {
                throwDefaultViewNotFound({
                    type: 'LIST',
                    entityName: entityDef.name,
                    viewName: defaultListView,
                });
            }
            List = getViewComponent(view);
        }
        if (entityDef?.defaultViews?.EDIT) {
            const defaultEditView = entityDef.defaultViews.EDIT.name;
            const view = viewConfig.views[defaultEditView];
            if (!view) {
                throwDefaultViewNotFound({
                    type: 'EDIT',
                    entityName: entityDef.name,
                    viewName: defaultEditView,
                });
            }
            Edit = getViewComponent(view);
        }
        if (entityDef?.defaultViews?.CREATE) {
            const defaultCreateView = entityDef.defaultViews.CREATE.name;
            const view = viewConfig.views[defaultCreateView];
            if (!view) {
                throwDefaultViewNotFound({
                    type: 'CREATE',
                    entityName: entityDef.name,
                    viewName: defaultCreateView,
                });
            }
            Create = getViewComponent(view);
        }
        if (entityDef?.defaultViews?.SHOW) {
            const defaultShowView = entityDef.defaultViews.SHOW.name;
            const view = viewConfig.views[defaultShowView];
            if (!view) {
                throwDefaultViewNotFound({
                    type: 'SHOW',
                    entityName: entityDef.name,
                    viewName: defaultShowView,
                });
            }
            Show = getViewComponent(view);
        }
        if (entityDef?.defaultViews?.MATCH) {
            const defaultMatchView = entityDef.defaultViews.MATCH.name;
            PossibleMatchView = getViewComponent(viewConfig.views[defaultMatchView]);
        }
        if (entityDef?.defaultViews?.MERGE) {
            const defaultMergeView = entityDef.defaultViews.MERGE.name;
            SpecificMatchView = getViewComponent(viewConfig.views[defaultMergeView]);
        }

        return {
            entityIndex: entityName,
            name: entityPlural,
            list: List,
            edit: Edit,
            create: Create,
            show: Show,
            possibleMatchView: PossibleMatchView,
            specificMatchView: SpecificMatchView,
        };
    });

export default getResourceStateFromViewConfig;
