import { saveAs } from 'file-saver';
import { BACKEND_BASE_URL } from 'config';
import buildHeaders from 'sideEffect/buildHeaders';

export const handleDumpThread = async (threadId: string) => {
    try {
        const url = `${BACKEND_BASE_URL}api/admin/compass/dump/${threadId}`;

        const response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: buildHeaders({
                includeCredentials: true,
                'Content-Type': 'application/json',
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to dump thread: ${response.status}`);
        }

        const blob = await response.blob();
        const filename = `thread_${threadId}.json`;

        saveAs(blob, filename);
    } catch (error) {
        console.error('Error dumping thread:', error);
    }
};
