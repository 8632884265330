import React from 'react';
import FieldTitle from '../../../fieldFactory/input/components/aor/FieldTitle';
import { Box, FormControl, InputLabel } from '@mui/material';

interface LabeledProps {
    basePath?: string;
    children: React.ReactNode;
    classes?: Record<string, any>;
    className?: string;
    fullWidth: boolean;
    input?: any;
    isRequired?: boolean;
    label: string;
    meta?: {
        touched: boolean;
        error: string;
    };
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    record?: any;
    resource?: string;
    source: string;
    fieldInstanceIdentifier?: string;
    shrink?: boolean;
    labelStyle?: React.CSSProperties;
    labelClassName?: string;
}

/**
 * Use any component as read-only Input, labeled just like other Inputs.
 *
 * Useful to use a Field in the Edit or Create components.
 * The child component will receive the current record.
 *
 * This component name doesn't have a typo. We had to choose between
 * the American English "Labeled", and the British English "Labelled".
 * We flipped a coin.
 *
 * @example
 * <Labeled label="Comments">
 *     <FooComponent source="title" />
 * </Labeled>
 */

const Labeled = ({
    children,
    className,
    classes,
    fullWidth,
    input,
    isRequired,
    label,
    meta,
    resource,
    source,
    shrink = true,
    labelStyle = {},
    labelClassName = '',
    ...rest
}: LabeledProps) => {
    if (!label && !source) {
        throw new Error(
            `Cannot create label for component <${
                React.isValidElement(children) && typeof children.type === 'function' && children.type.name
            }>: You must set either the label or source props. You can also disable automated label insertion by setting 'addLabel: false' in the component default props`,
        );
    }

    return (
        <FormControl
            className={className}
            margin="none"
            fullWidth={fullWidth}
            variant="standard"
            error={meta && meta.touched && !!meta.error}
        >
            <InputLabel shrink={shrink} sx={{ position: 'relative', ...labelStyle }} className={labelClassName}>
                <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
            </InputLabel>

            <Box
                sx={(theme) => ({
                    fontFamily: theme.typography.fontFamily,
                    color: 'currentColor',
                    padding: 0,
                    border: 0,
                    boxSizing: 'content-box',
                    verticalAlign: 'middle',
                    background: 'none',
                    margin: 0,
                    display: 'block',
                    width: '100%',
                })}
            >
                {React.isValidElement(children) && typeof children.type !== 'string'
                    ? React.cloneElement(children as any, {
                          input,
                          resource,
                          fullWidth,
                          source,
                          meta,
                          ...rest,
                      })
                    : children}
            </Box>
        </FormControl>
    );
};

export default Labeled;
