import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ManagedFormCreate from './Create/ManagedFormCreate';
import List from './List/List';
import Edit from './Edit/Edit';
import DecisionTableRoute from 'decision-table-editor';
import { Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const CasetivityManagedBpmRoute = () => {
    let match = useRouteMatch();

    const DECISION_TABLE_PATH = `${match.path}/decision-table`;
    const FORMS_PATH = `${match.path}/forms`;
    return (
        <div>
            <Switch>
                <Route path={DECISION_TABLE_PATH} component={() => <DecisionTableRoute />} />,
                <Route path={`${match.path}/create-form`} component={ManagedFormCreate} />
                <Route path={`${match.path}/form/:key`} component={Edit} />
                <Route path={FORMS_PATH} component={List} />
                <Route
                    path={`${match.path}/`}
                    component={() => (
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <div>
                                    <Link component={RouterLink} to={DECISION_TABLE_PATH}>
                                        <Typography variant="h4">Decision Tables</Typography>
                                    </Link>
                                </div>
                                <div>
                                    <Link component={RouterLink} to={FORMS_PATH}>
                                        <Typography variant="h4">Task Forms</Typography>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                />
            </Switch>
        </div>
    );
};

export default CasetivityManagedBpmRoute;
