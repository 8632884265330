import { Button, Card, Typography, useTheme } from '@material-ui/core';
import { ArrowBack, Save } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { push } from 'connected-react-router';
import ControlledDecisionTableEditor from 'decision-table-editor/ControlledDecisionTableEditor';
import DeleteIconPopup from 'decision-table-editor/delete/DeletePopupIcon';
import { DecisionTable } from 'decision-table-editor/domain';
import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { services } from 'sideEffect/services';
import useService from 'util/hooks/useService';

export const EditDecisionTableComponent = ({
    initialTable,
    renderSaveArea,
}: {
    initialTable: DecisionTable;

    renderSaveArea: (props: { definition: DecisionTable }) => JSX.Element;
}) => {
    const [state, setState] = useState<DecisionTable>(initialTable);
    return (
        <div>
            <div>
                <ControlledDecisionTableEditor state={state} setState={setState} />
            </div>
            {renderSaveArea({
                definition: state,
            })}
        </div>
    );
};

const EditDecisionTable = (
    props: RouteComponentProps<{ key: string }> & {
        refresh: () => void;
    },
) => {
    const theme = useTheme();
    const { refresh } = props;
    const [state, request] = useService(services.decisionTables.get);

    const dispatch = useDispatch();
    useEffect(() => {
        return request(props.match.params.key);
    }, [props.match.params.key, request]);

    const [submissionState, submit, { StateIcon: SubmissionStateIcon }] = useService(services.decisionTables.create);

    const saved = submissionState.status === 'success';
    useEffect(() => {
        if (saved) {
            refresh();
        }
    }, [saved, refresh]);
    return state.fold(
        () => null,
        () => null,
        (data) => (
            <Card style={{ padding: '.5em' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex' }}>
                        <Button size="small" component={Link} to="/admin/bpm/decision-table" startIcon={<ArrowBack />}>
                            Back
                        </Button>
                        <div style={{ marginLeft: '1em' }}>
                            <Typography variant="h5">Edit Decision Table {props.match.params.key}</Typography>
                        </div>
                    </div>
                    <DeleteIconPopup
                        onDeleteSuccess={() => {
                            dispatch(push(`/admin/bpm/decision-table`));
                        }}
                        decisionTableKey={props.match.params.key}
                    />
                </div>
                <EditDecisionTableComponent
                    initialTable={data}
                    renderSaveArea={({ definition }) => (
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                <Button
                                    disabled={submissionState.status === 'pending'}
                                    variant="contained"
                                    color="primary"
                                    endIcon={SubmissionStateIcon ?? <Save />}
                                    onClick={() => {
                                        submit(definition.key, definition);
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                            {submissionState.status === 'error' ? (
                                <div style={{ margin: theme.spacing(1) }}>
                                    <Alert severity="error">
                                        <AlertTitle>An error occurred</AlertTitle>
                                        <p>{submissionState.error.response?.description ?? null}</p>
                                    </Alert>
                                </div>
                            ) : null}
                        </div>
                    )}
                />
            </Card>
        ),
        (error) => <div>Error</div>,
    );
};

// remount inner component on save.
const EditDecisionTableWithRefresh = (props: RouteComponentProps<{ key: string }>) => {
    const [key, refresh] = useReducer((state) => state + 1, 1);
    return <EditDecisionTable {...props} key={key} refresh={refresh} />;
};
export default EditDecisionTableWithRefresh;
