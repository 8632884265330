import { isPlainObject } from 'lodash';

type AnyObject = { [key: string]: any };

export type PathTrie = {
    [key: string]: PathTrie;
};
export function buildTrie(paths: (string | string[])[]): PathTrie {
    const trie: PathTrie = {};
    for (const path of paths) {
        let node = trie;
        const subpaths = Array.isArray(path) ? path : path.split('.');
        for (const part of subpaths) {
            if (!node[part]) {
                node[part] = {};
            }
            node = node[part];
        }
    }
    return trie;
}

const createPathTree = (obj: AnyObject, paths: PathTrie | (string | string[])[]): AnyObject => {
    const pathsTrie = Array.isArray(paths) ? buildTrie(paths) : paths;
    function applyTrie(currentObj: any, trieNode: any): any {
        if (!isPlainObject(currentObj) && !Array.isArray(currentObj)) {
            return currentObj;
        }

        // In order to not mutate our inputs, we will replace this with a copy, when needed.
        // DO NOT modify this to mutate newObj, unless you overwrite this variable with a copy first.
        let newObj = {};
        if (Array.isArray(currentObj)) {
            if (Object.keys(trieNode).length === 0) {
                /**
                 * In cases like
                 *   #foo(myArray)
                 * where myArray is e.g.
                 *   [{ a: 1 }, { a: 2 }, ...]
                 * We should return the whole (original) array.
                 * If not for this line, it would end up filtered as
                 * [{}, {}, ...]
                 * because the PathTrie will only by 'myArray' and it will look like we don't need anything expanded in the objects within it.
                 *
                 * This is basically never an issue, except for e.g.
                 * getHtmlTableFromList(questionAnswers) // see CCS-459
                 *
                 * Note that if we then do
                 *
                 * getHtmlTableFromList(questionAnswers) && questionAnswers.![#this.answer]
                 * we would only get
                 * [{ answer: 'a'}, ...]
                 * and not the entire object.
                 *
                 * Let's say this is fine in most cases.
                 *
                 * That is because not many functions actually inspect the contents of objects in arrays.
                 * And the ones that do will _very_rarely_ also have selection/projection expressions on them.
                 */
                return currentObj;
            }
            newObj = currentObj.slice();
            for (let i = 0; i < currentObj.length; i++) {
                newObj[i] = applyTrie(newObj[i], trieNode['_ALL_']);
            }
        } else {
            for (const key in trieNode) {
                if (key !== '_ALL_') {
                    const nextObj = currentObj[key];
                    newObj[key] = applyTrie(nextObj, trieNode[key]);
                }
            }
            if (trieNode?.['_ALL_']) {
                newObj = currentObj;
            }
        }

        return newObj;
    }
    return applyTrie(obj, pathsTrie);
};

function isEmptyObject(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
}

export function setNullOnMissingPaths(obj: AnyObject, paths: PathTrie | (string | string[])[]): AnyObject {
    // Helper function to apply the trie to the object tree
    function applyTrie(currentObj: any, trieNode: any): any {
        // console.log('apply', currentObj, trieNode);
        if (!isPlainObject(currentObj) && !Array.isArray(currentObj) && (!trieNode || isEmptyObject(trieNode))) {
            return currentObj === undefined || currentObj === '' ? null : currentObj;
        }

        // In order to not mutate our inputs, we will replace this with a copy, when needed.
        // DO NOT modify this to mutate newObj, unless you overwrite this variable with a copy first.
        let newObj = currentObj ?? null;
        if (Array.isArray(currentObj)) {
            newObj = currentObj.slice();
            for (let i = 0; i < newObj.length; i++) {
                newObj[i] = applyTrie(newObj[i], trieNode['_ALL_']);
            }
        } else {
            let foundNonALLKey = false;
            for (const key in trieNode) {
                if (key !== '_ALL_') {
                    if (!foundNonALLKey) {
                        newObj =
                            typeof currentObj === 'object' || typeof currentObj === 'undefined'
                                ? // (we don't want to spread strings, for example)
                                  { ...currentObj }
                                : {};
                    }
                    foundNonALLKey = true;
                    const nextObj = newObj[key];
                    newObj[key] = applyTrie(nextObj, trieNode[key]);
                }
            }
        }

        return newObj;
    }

    const trie = Array.isArray(paths) ? buildTrie(paths) : paths;
    const res = applyTrie(obj, trie);
    return res;
}
export default createPathTree;
