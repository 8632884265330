const isObject = (obj: unknown): obj is Record<string, unknown> => typeof obj === 'object' && obj !== null;

export type PathTrie = {
    [key: string]: PathTrie;
};
/**
 *
 * In contrast to createPathTree, this expands the record according to the trie, but includes all properties on records found along the way
 * (instead of expanding only, literally, the keys found in the trie)
 */
const unwrapAlongtrie = (obj: unknown, trie?: PathTrie) => {
    if (trie && Array.isArray(obj)) {
        // arrays usually have many items, so don't unwrap unnecessarily.
        const res = [];
        for (const item of obj) {
            res.push(unwrapAlongtrie(item, trie['_ALL_']));
        }
        return res;
    }
    if (isObject(obj)) {
        const res = {};
        const copyReferences = !!trie;
        for (const key in obj) {
            const value = obj[key];
            if (isObject(value) || Array.isArray(value)) {
                if (copyReferences) {
                    res[key] = unwrapAlongtrie(value, trie[key]);
                }
            } else {
                res[key] = value;
            }
        }
        return res;
    }
    return obj;
};
export default unwrapAlongtrie;
