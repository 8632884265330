import { useTheme } from '@mui/material';
import { useEvaluateTemplateInFormContext } from 'expressions/hooks/allForms/useEvaluateTemplate';
import React from 'react';
import useLabelLineCount from './useLabelLineCount';
import useTextFieldUtils from 'fieldFactory/input/hooks/useTextFieldUtils';

interface FieldTitleProps {
    label: string;
    isRequired?: boolean;
    appendScreenreaderText?: string;
}

const InnerEvaluateLabel = (props: {
    label?: string;
    children: (props: { templatedLabel?: string }) => JSX.Element;
}) => {
    const templatedLabel = useEvaluateTemplateInFormContext(props.label);
    return props.children({ templatedLabel });
};
export const EvaluateLabel = (props: {
    label?: string;
    children: (props: { templatedLabel?: string }) => JSX.Element;
}) => {
    return props.label?.includes('$[') ? (
        <InnerEvaluateLabel {...props} />
    ) : (
        props.children({
            templatedLabel: props.label,
        })
    );
};

const FieldTitleComponent: React.FC<FieldTitleProps> = ({ label, isRequired, appendScreenreaderText }) => {
    const theme = useTheme();
    const { labelRef, lineCount } = useLabelLineCount();
    const { fieldVariant } = useTextFieldUtils({});
    // need less spacing with outlined variant
    const spacing = fieldVariant === 'outlined' ? 1.5 : 2.4;

    const labelStyle: React.CSSProperties = {
        position: 'relative',
        top: lineCount > 1 ? `-${theme.spacing(spacing * (lineCount - 1))}` : undefined,
    };

    if (!label) {
        return null;
    }

    const appendElem = appendScreenreaderText ? (
        <span className="casetivity-off-screen">{appendScreenreaderText}</span>
    ) : null;
    return label.endsWith('*') ? (
        <span style={{ ...labelStyle }} ref={labelRef}>
            {label.slice(0, -1)}
            <abbr title="Required">*</abbr>
            {appendElem}
        </span>
    ) : isRequired ? (
        <span style={{ ...labelStyle }} ref={labelRef}>
            {label} <abbr title="Required">*</abbr>
            {appendElem}
        </span>
    ) : (
        <span style={{ ...labelStyle }} ref={labelRef}>
            {label}
            {appendElem}
        </span>
    );
};

const TemplatedFieldTitle: React.FC<any> = React.memo(
    ({ label: _label, isRequired, appendScreenreaderText }: FieldTitleProps) => {
        const label = useEvaluateTemplateInFormContext(_label);
        return (
            <FieldTitleComponent
                label={label}
                isRequired={isRequired}
                appendScreenreaderText={appendScreenreaderText}
            />
        );
    },
);

export const FieldTitle: React.FC<any> = (props) => {
    if (props.label?.includes('$[')) {
        return <TemplatedFieldTitle {...props} />;
    }
    return <FieldTitleComponent {...props} />;
};

export default FieldTitle;
