import React, { useMemo, useState } from 'react';
import { Button, Typography, useTheme, lighten, Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useChatBot } from 'chatbot/ChatbotProvider';
import { ChatResponseWrapper } from 'chatbot/components/ResponseBox';
import { assistants } from 'chatbot/constants';

const PageChange = ({ currentUrl }) => {
    const theme = useTheme();
    const history = useHistory();
    const { state: chatState, refresh, setConvoUrl, enableInputSendButton, updateAssistantContext } = useChatBot();
    const assistantDisplayName = useMemo(
        () => assistants.find((assistant) => assistant.assistant === chatState.assistant)?.displayName,
        [chatState.assistant],
    );
    const [returnedToPage, setReturnedToPage] = useState(false);

    const returnMessage = useMemo(
        () => `You have successfully navigated back to: ${chatState.convoUrl}.`,
        [chatState.convoUrl],
    );

    const handleReturn = () => {
        history.push(chatState.convoUrl);
        enableInputSendButton();
        setReturnedToPage(true);
    };

    const handleContinue = () => {
        history.push(currentUrl);
        updateAssistantContext('casetivity-chat');
        refresh();
        setConvoUrl(currentUrl);
        enableInputSendButton();
    };

    return (
        <ChatResponseWrapper>
            {returnedToPage ? (
                <Typography variant="body2">{returnMessage}</Typography>
            ) : (
                <Box
                    sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: 1 }}
                >
                    <Typography variant="body2" mb={1}>
                        This {assistantDisplayName} conversation started on a different page. Please choose an option
                        below:
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Button
                            variant="outlined"
                            sx={{
                                border: `1px solid ${theme.palette.primary.contrastText}`,
                                color: theme.palette.primary.contrastText,
                                flexGrow: 1,
                                mr: 1,
                                '&:hover': {
                                    border: `1px solid ${theme.palette.primary.contrastText}`,
                                    backgroundColor: lighten(theme.palette.primary.main, 0.05),
                                },
                            }}
                            onClick={handleContinue}
                        >
                            Clear Chat and Continue
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                border: `1px solid ${theme.palette.primary.contrastText}`,
                                color: theme.palette.primary.contrastText,
                                flexGrow: 1,
                                mr: 1,
                                '&:hover': {
                                    border: `1px solid ${theme.palette.primary.contrastText}`,
                                    backgroundColor: lighten(theme.palette.primary.main, 0.05),
                                },
                            }}
                            onClick={handleReturn}
                        >
                            Return to {chatState.convoUrl}
                        </Button>
                    </Box>
                </Box>
            )}
        </ChatResponseWrapper>
    );
};

export default PageChange;
