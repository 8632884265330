import { RootState, useAppSelector } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';

const useUserIsSuper = () => {
    return useAppSelector((state: RootState) => {
        return fromNullable(state.viewConfig)
            .mapNullable((vc) => vc.user)
            .mapNullable((u) => u.roles)
            .map((roles) => roles.includes('ROLE_SUPER'))
            .getOrElse(false);
    });
};
export default useUserIsSuper;
