import * as React from 'react';
import { createSelector } from 'reselect';
import compose from 'recompose/compose';
import { connect, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { CardActions, Button, CircularProgress, Typography, ButtonProps, CardHeader } from '@material-ui/core';
import Save from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import NavigationRefresh from '@material-ui/icons/Refresh';
import EditButton from '../../button/EditButton';
import ShowButton from '../../button/RedirectShowButton';
import * as ViewConfigUtils from '../../utils/viewConfigUtils/index';
import applyMappings, { ExpressionType } from '../applyMappings';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { evaluateExpression } from 'expressions/evaluate';
import WithErrorBoundary from '../../fields/WithErrorBoundary';
import { parseConfig } from 'expressions/entityViewConfig/parse';
import { fromNullable, fromEither } from 'fp-ts/lib/Option';
import { reverseLookupAccessLevel } from 'util/accessControlThroughLinkedEntity';
import { createGetEntities } from '../../form/EntityFormContext/util/getEntities';
import { getRecordFieldsRequiredForActionButtons } from 'clients/utils/createExpansionQuery/buildCommaSeperatedExpansions';
import { denormalizeEntitiesByPaths } from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/denormalizing/buildEntityMappingsFromPaths';
import { casetivityViewContext } from 'util/casetivityViewContext';
import produce from 'immer';
import get from 'lodash/get';
import set from 'lodash/set';
import { expressionTesterProvidedViewConfigurationContext } from 'expression-tester/entity-form';
import InlineDeleteButton from '../../button/InlineDeleteButton';
import useViewConfig from 'util/hooks/useViewConfig';
import { entityAndConceptLookupUtils } from 'expressions/expressionArrays';
import useEntities from 'util/hooks/useEntities';
import { mapOption } from 'fp-ts/lib/Array';
import { fromPredicate } from 'fp-ts/lib/Option';
import useValueSets from 'util/hooks/useValueSets';
import uniq from 'lodash/uniq';
import getFieldsRequiredForExpression from 'clients/utils/getFieldsRequiredForExpression';
import { EntityViewConfig } from 'expressions/entityViewConfig/type';
import { isRefManyField, isRefManyManyField } from '../../utils/viewConfigUtils/index';
import ViewConfig from 'reducers/ViewConfigType';
import { createRootContext } from '@mkanai/casetivity-shared-js/lib/spel/evaluation-context';
import isOffline from 'util/isOffline';
import Popup from 'components/Popup';
import StartForm from 'bpm/start-form/components';
import { refreshContext } from 'components/generics/form/refreshContext';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { parse } from 'querystring';
import { formContext } from 'components/generics/form/EntityFormContext';
import { EA, EntityDispatchAction, StartFormAction, EntityActions } from './EntityActions';
import { useEvaluateAdhocTemplateInEntityContext } from 'expressions/hooks/EvalInEntityContextStore/EvalInEntityContextProvider';

// MUTATES draftState
// have to null-init things like 'organizations.name' where we apply to all array entries.
// also only set last items in path (they might be missing because they are not set values)
// DON'T create false expansions (expansions should guarantee the data is there and if not there is a problem.)
// the SPEL expression can short circuit on its own.
export const nullInitializeData = (draftState: {}, path: string) => {
    const pathArr = path.split('.');
    pathArr.every((curr, i) => {
        const pathSoFar = pathArr.slice(0, i + 1).join('.');
        const currentValue = get(draftState, pathSoFar);
        // if it's an array and we have more to do, handle the remainder for all items in the array.
        if (Array.isArray(currentValue) && i !== path.length) {
            currentValue.forEach((v) => {
                nullInitializeData(v, pathArr.slice(i + 1).join('.'));
            });
            // don't continue
            return false;
        }
        // if the value is missing, null initialize it and don't continue.
        else if (typeof currentValue === 'undefined') {
            set(draftState, pathSoFar, null);
            return false;
        }
        return true;
    });
    return draftState;
};

const cardActionStyle: React.CSSProperties = {
    padding: '.5em',
    zIndex: 0,
    display: 'inline-block',
    float: 'left',
};

class CustomDispatchButtonComponent extends React.Component<
    {
        label: string;
        onClick: (onRequestEnd?: () => void) => void;
        ButtonProps?: ButtonProps;
    },
    { inProgress: boolean }
> {
    state = { inProgress: false };
    handleClick = () => {
        this.setState({ inProgress: true }, () => this.props.onClick(() => this.setState({ inProgress: false })));
    };
    render() {
        const { label, ButtonProps = {} } = this.props;
        return (
            <Button
                onMouseDown={(e) => {
                    e.stopPropagation();
                }}
                disabled={this.state.inProgress}
                color="primary"
                {...ButtonProps}
                onClick={this.handleClick}
            >
                {label}
            </Button>
        );
    }
}
const CustomDispatchButton = compose(
    connect(
        null,
        (
            dispatch,
            ownProps: {
                actionConf: {
                    redirectOnSuccess?: ExpressionType;
                    action: ExpressionType;
                };
                data: {};
                resource: string;
            },
        ) => ({
            onClick: (onRequestEnd?: () => void) => {
                dispatch(
                    ownProps.actionConf.redirectOnSuccess
                        ? {
                              ...applyMappings(
                                  ownProps.actionConf.action,
                                  [ownProps.data, ownProps.resource],
                                  ['record', 'resource'],
                              ),
                              cb: (payload, resultData) => {
                                  if (onRequestEnd) {
                                      onRequestEnd();
                                  }
                                  dispatch(
                                      push(
                                          applyMappings(
                                              ownProps.actionConf.redirectOnSuccess!,
                                              [payload, resultData, ownProps.resource],
                                              ['record', 'response', 'resource'],
                                          ),
                                      ),
                                  );
                              },
                              errorCb: onRequestEnd,
                          }
                        : applyMappings(ownProps.actionConf.action, ownProps.data),
                );
            },
        }),
    ),
)(CustomDispatchButtonComponent);

function isDispatchActionButton(buttonDef: EA): buttonDef is EntityDispatchAction {
    return Boolean((buttonDef as EntityDispatchAction).action);
}

function isStartFormActionButton(buttonDef: EA): buttonDef is StartFormAction {
    return Boolean((buttonDef as StartFormAction).processDefinitionKey);
}

const getExpandedRecordSelector = <
    P extends {
        viewName: string;
        id: string;
        overrideViewConfig?: ViewConfig;
        overrideActionButtonExp?: RootState['actionButtonExps'][0];
        entityViewConfig: EntityViewConfig;
    },
>() => {
    const getEntities = createGetEntities();
    return createSelector(
        (state: RootState, props: P) => props.overrideActionButtonExp || state.actionButtonExps[props.viewName],
        (state: RootState, props: P) => props.overrideViewConfig || state.viewConfig,
        getEntities,
        (state: RootState, props: P) => props.id,
        (state: RootState, props: P) => (props.overrideViewConfig || state.viewConfig).views[props.viewName].entity,
        (state: RootState, props: P) => props.entityViewConfig,
        (actionButtonExps, viewConfig, entities, id, entityType, entityViewConfig) => {
            const { hideEditButton, hideDeleteButton } = entityViewConfig;
            if (id) {
                const fieldsRequired = (() => {
                    let acc: string[] = [];
                    if (actionButtonExps) {
                        acc = acc.concat(getRecordFieldsRequiredForActionButtons(actionButtonExps));
                    }
                    if (hideEditButton && typeof hideEditButton === 'string') {
                        acc = acc.concat(getFieldsRequiredForExpression(hideEditButton));
                    }
                    if (hideDeleteButton && typeof hideDeleteButton === 'string') {
                        acc = acc.concat(getFieldsRequiredForExpression(hideDeleteButton));
                    }
                    return acc;
                })();
                // paths relative to record
                const recordPaths = mapOption(
                    fieldsRequired,
                    fromPredicate<string>(
                        (_p) =>
                            // lets say that if we wanted 'roles' as a field, we would simply use record.roles here
                            !_p.startsWith('roles'),
                    ),
                )
                    .map((p) => (p.startsWith('record.') ? p.slice('record.'.length) : p))
                    .map((p) => ViewConfigUtils.getFieldSourceFromPath(viewConfig, entityType, p));
                // data we need to expand so 'fieldsRequired' is covered
                const pathsToExpand = mapOption(recordPaths, (p) =>
                    fromPredicate<string>((p) => p.includes('.'))(p).map((p) => p.slice(0, p.lastIndexOf('.'))),
                );
                const expandedRoot = denormalizeEntitiesByPaths(entities, pathsToExpand, viewConfig, entityType, id);
                return produce(expandedRoot, (draftState) => {
                    let paths = uniq(
                        recordPaths.map((f) => {
                            const sps = f.split('.');
                            for (let i = 0; i < sps.length; i++) {
                                const pathSoFar = sps.slice(0, i + 1).join('.');
                                if (
                                    // don't null initialize past an 'absent array' since we don't want, e.g. organizations: { name: null}
                                    // when it should be organizations: [{ name: ... }]
                                    !Array.isArray(get(expandedRoot, pathSoFar)) &&
                                    (isRefManyField(viewConfig, entityType, pathSoFar, 'TRAVERSE_PATH') ||
                                        isRefManyManyField(viewConfig, entityType, pathSoFar, 'TRAVERSE_PATH'))
                                ) {
                                    return pathSoFar;
                                }
                            }
                            return f;
                        }),
                    );
                    /**
                     * If we have both partner and partner.investigations
                     * we don't want to null initialize partner.investigations
                     * because if we did a partner == null check, that would end up wrong
                     * because now we have { partner: { investigations: null }}
                     *
                     * So if we discover a path has a shorter subpath in the list,
                     * remove it, so we don't null initialize it!!!
                     *
                     * In other words, if we have paths of multiple depths along a chain used,
                     * null-initialize the shortest one.
                     */
                    paths = paths.filter((path) => {
                        const subpaths = path.split('.').reduce((prev, curr, i, arr) => {
                            const isLast = i === arr.length - 1;
                            if (isLast) {
                                return prev;
                            }
                            const prevJoined = prev.join('.');
                            prev.push(prevJoined ? prevJoined + '.' + curr : curr);
                            return prev;
                        }, []);

                        return !paths.some((p) => subpaths.includes(p));
                    });

                    paths.forEach((path) => {
                        nullInitializeData(draftState, path);
                    });
                    return draftState;
                });
            }
            return fromNullable(entities[entityType])
                .mapNullable((e) => e[id])
                .getOrElse(undefined);
        },
    );
};
export const useExpandedRecord = (args: {
    viewName: string;
    id: string;
    overrideViewConfig?: ViewConfig;
    overrideActionButtonExp?: RootState['actionButtonExps'][0];
    entityViewConfig: EntityViewConfig;
}) => {
    const expandedRecordSelector = React.useMemo(getExpandedRecordSelector, []);
    return useAppSelector((state: RootState) => expandedRecordSelector(state, args));
};

const viewSelector = <P extends { viewName: string; overrideViewConfig?: ViewConfig }>(state: RootState, ownProps: P) =>
    ownProps.overrideViewConfig
        ? ownProps.overrideViewConfig.views[ownProps.viewName]
        : state.viewConfig.views && state.viewConfig.views[ownProps.viewName];

const makeConfigSelector = () => {
    const emptyObj = {};
    return createSelector(viewSelector, (view) => {
        return fromNullable(view)
            .map((v) => v.config)
            .chain(fromNullable)
            .map(parseConfig)
            .chain(fromEither)
            .getOrElse(emptyObj);
    });
};

const useConfig = (args: { viewName: string; overrideViewConfig?: ViewConfig }) => {
    const configSelector = React.useMemo(makeConfigSelector, []);
    return useAppSelector((state: RootState) => configSelector(state, args));
};
const useLinkedEntityType = (args: { resource: string; id: string }) => {
    return useAppSelector((state: RootState) => {
        return fromNullable(state.admin.entities[args.resource])
            .mapNullable((e) => e[args.id])
            .mapNullable((e) => (e as any).linkedEntityType)
            .toNullable();
    });
};

export interface OverrideablePermissionedActionsProps {
    disableDefaultActions?: boolean;
    viewName: string;
    id: string;
    hardActionsConfig?: {
        [key: string]: EntityActions;
    };
    refresh?: () => void;
    hasShow?: boolean;
    hasEdit?: boolean;
    hasDelete?: boolean;
    save?: () => void;
    isSaving?: boolean;
    filter?: (conf: EntityActions[0], i: number) => boolean;
    overrideActionButtonExp?: RootState['actionButtonExps'][0];
    sameEntityExistsBelowInTheStack?: () => boolean;
}

const useIsDirty = () => {
    const efc = React.useContext(formContext);
    return efc.isDirty;
};

const StartFormPopup: React.FC<{ actionButtonConf: StartFormAction; ButtonProps?: Partial<ButtonProps> }> = ({
    actionButtonConf,
    ButtonProps,
}) => {
    const isDirty = useIsDirty();
    const [success, setSuccess] = React.useState(false);
    const [pendingRedirection, setPendingRedirection] = React.useState(false);
    const refresh = React.useContext(refreshContext);
    const { params: paramsString = '', successMessage } = actionButtonConf;
    const evaluatedParams = useEvaluateAdhocTemplateInEntityContext(paramsString, 'live');
    const params = React.useMemo(() => {
        if (!evaluatedParams) {
            return {};
        }
        return parse(evaluatedParams.startsWith('?') ? evaluatedParams.slice(1) : evaluatedParams);
    }, [evaluatedParams]);
    const debugMode = useAppSelector((state: RootState) => state.debugMode);
    const dispatch = useDispatch();
    const interceptSuccess = React.useCallback(
        (redirect?: string) => {
            setSuccess(true);
            if (!redirect || redirect === window.location.pathname) {
                return;
            }
            setPendingRedirection(true);
            setTimeout(() => {
                if (redirect.startsWith('http')) {
                    window.location.href = redirect;
                } else {
                    dispatch(push(redirect));
                }
            }, 2000);
        },
        [setSuccess, setPendingRedirection, dispatch],
    );
    return (
        <Popup
            ComponentProps={
                isDirty
                    ? undefined
                    : {
                          fullWidth: true,
                      }
            }
            paperStyle={
                isDirty
                    ? undefined
                    : {
                          maxWidth: '2048px',
                      }
            }
            onClose={() => {
                refresh?.(undefined, true);
                if (success) {
                    setTimeout(() => {
                        setSuccess(false);
                        setPendingRedirection(false);
                    }, 100);
                }
            }}
            key={'start-form:' + actionButtonConf.key}
            renderDialogContent={({ closeDialog }) => {
                if (isDirty) {
                    return (
                        <div>
                            <CardHeader title={'You have unsaved changes on this page.'} />
                            <div style={{ margin: '0 auto', width: '50%' }}>
                                <span>Please save your work to continue.</span>
                            </div>
                            <CardActions>
                                <Button color="primary" variant="contained" onClick={closeDialog}>
                                    Close
                                </Button>
                            </CardActions>
                        </div>
                    );
                }
                return (
                    <div>
                        {debugMode && (
                            <div>
                                <p style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(params, null, 1)}</p>
                            </div>
                        )}
                        {!success ? (
                            <StartForm
                                useInternalFormId
                                isPopover
                                businessKey={actionButtonConf.processDefinitionKey}
                                queryParams={params}
                                interceptSuccess={interceptSuccess}
                                renderBeforeOutcomes={() => (
                                    <span style={{ marginRight: '1rem' }}>
                                        <Button variant="contained" onClick={closeDialog}>
                                            Cancel
                                        </Button>
                                    </span>
                                )}
                            />
                        ) : (
                            <div style={{ height: '100%', width: '100%' }}>
                                {/* Make this nice, and add an 'ok' or 'close' button.. */}
                                <div style={{ padding: '2em', textAlign: 'center' }}>
                                    <Typography variant="h5" component="div">
                                        {successMessage || 'Process Started Successfully'}
                                    </Typography>
                                    {pendingRedirection && (
                                        <Typography component="div" variant="h6">
                                            Please wait while you are redirected...
                                        </Typography>
                                    )}
                                    <div style={{ marginTop: '2em' }}>
                                        <CheckCircle color="primary" style={{ height: 75, width: 75 }} />
                                    </div>
                                </div>
                                <div style={{ padding: '1em' }}>
                                    <Button variant="contained" color="primary" onClick={closeDialog}>
                                        Close
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                );
            }}
            renderToggler={({ openDialog }) => (
                <Button color="primary" {...ButtonProps} onClick={openDialog()}>
                    {actionButtonConf.label}
                </Button>
            )}
        />
    );
};

export const getRenderActionButton =
    (expandedRecord: {}, resource: string) =>
    (actionButton: EA, ButtonProps: Partial<ButtonProps> = {}) =>
        isStartFormActionButton(actionButton) ? (
            <StartFormPopup actionButtonConf={actionButton} ButtonProps={ButtonProps} />
        ) : isDispatchActionButton(actionButton) ? (
            <CustomDispatchButton
                data={expandedRecord}
                key={`customDispatch-${actionButton.label}`}
                ButtonProps={ButtonProps}
                actionConf={actionButton}
                label={actionButton.label}
            />
        ) : actionButton.label === 'Possible Matches' ? (
            expandedRecord &&
            expandedRecord['casetivityCanMerge'] && (
                <Button
                    key={`custom-link-${actionButton.key}`}
                    color="primary"
                    {...(ButtonProps as unknown as Partial<ButtonProps<Link>>)}
                    component={Link}
                    to={applyMappings(actionButton.url, [expandedRecord, resource], ['record', 'resource'])}
                >
                    {actionButton.label}
                </Button>
            )
        ) : (
            <Button
                key={`custom-link-${actionButton.key}`}
                color="primary"
                {...(ButtonProps as unknown as Partial<ButtonProps<Link>>)}
                component={Link}
                to={applyMappings(actionButton.url, [expandedRecord, resource], ['record', 'resource'])}
            >
                {actionButton.label}
            </Button>
        );

const OverrideablePermissionedActions: React.FunctionComponent<OverrideablePermissionedActionsProps> = (props) => {
    const {
        viewName,
        id,
        hardActionsConfig,
        refresh,
        filter = (conf, i) => true,
        hasShow,
        hasEdit,
        hasDelete,
        save,
        isSaving,
        overrideActionButtonExp,
        disableDefaultActions,
        sameEntityExistsBelowInTheStack,
    } = props;
    const dispatch = useDispatch();
    const viewConfig = useViewConfig();
    const resource = viewConfig.views[viewName].entity;
    const accessLevel = ViewConfigUtils.getAccessLevelForEntity(viewConfig, resource);
    const maybeOverride = React.useContext(expressionTesterProvidedViewConfigurationContext)?.config;
    const _config = useConfig({ viewName, overrideViewConfig: viewConfig });
    const config = React.useMemo(() => {
        const c = maybeOverride?.[props.viewName] || _config;

        const configuredEntityActionKeys = (c.entityActions ?? []).reduce((prev, curr) => {
            prev[curr.key] = true;
            return prev;
        }, {} as { [key: string]: true });
        // update with any hardcoded configurations we have
        return produce(c, (draftC) => {
            draftC.entityActions = (draftC.entityActions || []).concat(
                Object.entries(hardActionsConfig || {}).flatMap(([regexString, actionConfigs]) => {
                    const rx = new RegExp(regexString);
                    if (rx.test(resource)) {
                        // here, if we have a custom action defined which has the same key as a hardcoded one,
                        // remove the hardcoded action. This allows us to override and hide 'possible-matches' and 'impersonate-user'
                        return actionConfigs.filter(({ key }) => !configuredEntityActionKeys[key]);
                    }
                    return [];
                }),
            );
            return draftC;
        });
    }, [maybeOverride, props.viewName, _config, hardActionsConfig, resource]);
    const linkedEntityType = useLinkedEntityType({ resource, id });
    const { rootViewContext: viewContext } = React.useContext(casetivityViewContext);
    const expandedRecord = useExpandedRecord({
        id,
        viewName,
        overrideActionButtonExp,
        overrideViewConfig: viewConfig,
        entityViewConfig: config,
    });
    const _impersonating = useAppSelector((state: RootState) => state.impersonating?.type === 'success' ?? false);
    const entities = useEntities();
    const valueSets = useValueSets();
    const reverseLookupLinkedEntityPermission = reverseLookupAccessLevel(viewConfig, linkedEntityType, resource);
    const adjustedAccessLevel = reverseLookupLinkedEntityPermission
        ? (() => {
              if (reverseLookupLinkedEntityPermission === 3) {
                  return accessLevel;
              }
              return Math.min(accessLevel, reverseLookupLinkedEntityPermission);
          })()
        : accessLevel;
    const context = {
        record: expandedRecord,
        resource,
        viewContext,
        ...expandedRecord,
        roles: viewConfig.user.roles || {},
        _impersonating,
        ...entityAndConceptLookupUtils(entities, viewConfig, valueSets),
        ...createRootContext({
            options: {
                viewContext,
                dateFormat: viewConfig.application.dateFormat,
            },
        }),
        viewConfig,
        ...ViewConfigUtils,
    };
    const forceHideDeleteButton = fromNullable(config.hideDeleteButton)
        .map((dbv) => {
            return (
                evaluateExpression(dbv, context, context, {
                    disableBoolOpChecks: true,
                    disableNullPointerExceptions: true,
                }) === true
            );
        })
        .getOrElse(false);

    const forceHideEditButton = fromNullable(config.hideEditButton)
        .map(
            (dbv) =>
                evaluateExpression(dbv, context, context, {
                    disableBoolOpChecks: true,
                    disableNullPointerExceptions: true,
                }) === true,
        )
        .getOrElse(false);

    return !expandedRecord ? null : (
        <CardActions style={cardActionStyle}>
            {hasShow && expandedRecord && (
                <ShowButton disabled={disableDefaultActions} record={expandedRecord} resource={resource} />
            )}
            {!forceHideEditButton &&
                hasEdit &&
                ViewConfigUtils.allowsEdit(accessLevel) &&
                expandedRecord &&
                expandedRecord['casetivityCanEdit'] && (
                    <EditButton disabled={disableDefaultActions} id={expandedRecord.id} resource={resource} />
                )}
            {!isOffline() &&
                !forceHideDeleteButton &&
                hasDelete &&
                ViewConfigUtils.allowsDelete(accessLevel) &&
                expandedRecord &&
                expandedRecord['casetivityCanDelete'] && (
                    <InlineDeleteButton
                        resource={resource}
                        id={id}
                        sameEntityExistsBelowInTheStack={sameEntityExistsBelowInTheStack}
                        renderIcon={({ handleClick, classes }) => (
                            <Button
                                disabled={disableDefaultActions}
                                color="secondary"
                                onClick={handleClick}
                                className={classes.del}
                            >
                                Delete <DeleteIcon />
                            </Button>
                        )}
                        onDeleteSuccess={() => {
                            /* redirect to list */
                            dispatch(
                                push(
                                    `/${viewConfig.views[viewConfig.entities[resource].defaultViews.LIST.name].route}`,
                                ),
                            );
                        }}
                    />
                )}
            {React.Children.map(props.children, (child) => {
                if (!React.isValidElement(child)) {
                    return child;
                }
                const childMinAccessLevel = child.props['data-minAccessLevel'];
                if (
                    !childMinAccessLevel ||
                    (childMinAccessLevel <= adjustedAccessLevel &&
                        !(
                            (forceHideEditButton || expandedRecord['casetivityCanEdit'] === false) &&
                            childMinAccessLevel === 3
                        ) &&
                        !(
                            (forceHideDeleteButton || expandedRecord['casetivityCanDelete'] === false) &&
                            childMinAccessLevel === 5
                        ))
                ) {
                    if (sameEntityExistsBelowInTheStack) {
                        return React.cloneElement(child as any, { sameEntityExistsBelowInTheStack });
                    }
                    return child;
                }
                return null;
            })}
            {refresh && (
                <Button color="primary" onClick={refresh}>
                    Refresh&nbsp;
                    <NavigationRefresh />
                </Button>
            )}
            {save && (
                <Button color="primary" onClick={save} disabled={disableDefaultActions || isSaving} type="submit">
                    Save <Save /> {isSaving ? <CircularProgress style={{ height: 15, width: 15 }} /> : null}
                </Button>
            )}
            {isOffline()
                ? null
                : config.entityActions
                      .filter(filter)
                      .filter(
                          (abd) => !abd.displayRule || evaluateExpression(abd.displayRule, context, context) === true,
                      )
                      .map((conf) => getRenderActionButton(expandedRecord, resource)(conf))}
        </CardActions>
    );
};

const Actions: React.FunctionComponent<OverrideablePermissionedActionsProps> = (props) => {
    return (
        <WithErrorBoundary>
            <OverrideablePermissionedActions {...props} />
        </WithErrorBoundary>
    );
};
export default Actions;
