import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import DecisionTableList from './DecisionTableList';
import CreateDecisionTable from './create';
import EditDecisionTable from './edit';

const DecisionTableRoute = () => {
    let match = useRouteMatch();
    return (
        <div>
            <Switch>
                <Route path={`${match.path}/create`} component={CreateDecisionTable} />
                <Route path={`${match.path}/edit/:key`} component={EditDecisionTable} />
                <Route path={`${match.path}`} component={DecisionTableList} />
            </Switch>
        </div>
    );
};

export default DecisionTableRoute;
