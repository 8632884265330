import React from 'react';
import { checkLastRequestTime, checkLastRequestTimeSuccess } from '../actions';
import { useDispatch } from 'react-redux';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { CardHeader, Card, CardContent, CardActions, Button } from '@material-ui/core';
import useLogout from 'auth/hooks/useLogout';
import isOffline from 'util/isOffline';
import isTheInstalledApp from 'util/isTheInstalledApp';
import Dialog from '@mui/material/Dialog';

const NUMBER_OF_FAILURES_AFTER_WHICH_TO_ALERT = 4;

const OfflineAlert = () => {
    const dispatch = useDispatch();
    const logout = useLogout();
    const tooManyFailures = useAppSelector(
        (state: RootState) => state.reauth.successiveFailureCount >= NUMBER_OF_FAILURES_AFTER_WHICH_TO_ALERT,
    );
    const a401Occurred = useAppSelector((state: RootState) => state.reauth.a401Occurred);
    const isOnline = useAppSelector((state: RootState) => state.connectivityStatus);

    const someOfflineTasks = useAppSelector((state: RootState) =>
        Boolean(state.offlineTasks && Object.keys(state.offlineTasks).length > 0),
    );
    const suggestGoOfflineApp = !isOffline() && isTheInstalledApp() && someOfflineTasks;

    if (isOnline && !isOffline() && (tooManyFailures || a401Occurred)) {
        return (
            <Dialog
                open={true}
                TransitionProps={
                    {
                        // https://github.com/dequelabs/axe-core/issues/146
                        role: 'presentation',
                    } as any
                }
            >
                <Card>
                    <CardHeader title={a401Occurred ? 'Session timed out.' : 'There appears to be a problem.'} />
                    <CardContent>
                        {a401Occurred ? (
                            'Your session has timed out. To avoid losing your work, log in using another window, then click "Attempt to recover session" below. Otherwise, click "Go to login"'
                        ) : suggestGoOfflineApp ? (
                            <>
                                You may have lost network connectivity for too long. Attempt to reconnect, or ignore and
                                continue. You may also{' '}
                                <a href="/?offline=1">switch to the offline version of the app</a>
                            </>
                        ) : (
                            'You may have lost network connectivity for too long. Attempt to reconnect, or ignore and continue:'
                        )}
                    </CardContent>
                    <CardActions>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => dispatch(checkLastRequestTime(false))}
                        >
                            Attempt to recover session
                        </Button>
                        {a401Occurred ? (
                            <Button color="primary" variant="contained" onClick={logout}>
                                Go to login
                            </Button>
                        ) : null}
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => dispatch(checkLastRequestTimeSuccess())}
                        >
                            Close
                        </Button>
                    </CardActions>
                </Card>
            </Dialog>
        );
    }
    return null;
};

export default OfflineAlert;
