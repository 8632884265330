import { useReducer, useCallback } from 'react';
import { AssistantType, CASETIVITY_CHAT } from './constants';
import { ChatAction, ChatState, CurrentReportType, Message } from './chatbotTypes';

const chatBotReducer = (state: ChatState, action: ChatAction): ChatState => {
    switch (action.type) {
        case 'REFRESH':
            return {
                ...initialState,
                isOpen: state.isOpen,
                refreshKey: state.refreshKey + 1,
                assistant: state.assistant,
            };
        case 'DISABLE_INPUT_SEND_BUTTON':
            return { ...state, disableInputSendButton: true };
        case 'ENABLE_INPUT_SEND_BUTTON':
            return { ...state, disableInputSendButton: false };
        case 'TOGGLE_CHAT':
            return { ...state, isOpen: !state.isOpen };
        case 'UPDATE_CHAT_CONTEXT':
            return { ...state, ...action.payload };
        case 'UPDATE_PAGE_CONTEXT':
            return { ...state, pageContext: { ...state.pageContext, ...action.payload } };
        case 'UPDATE_ASSISTANT_CONTEXT':
            return { ...state, assistant: action.payload };
        case 'UPDATE_REPORT_CONTEXT':
            return { ...state, reportParameters: action.payload };
        case 'UPDATE_FIELD_FILLER':
            return { ...state, fieldFiller: action.payload };
        case 'SET_PREV_VALUE':
            return { ...state, prevValue: action.payload };
        case 'ADD_TO_HISTORY':
            return {
                ...state,
                chatHistory: {
                    threadId: action.payload.threadId,
                    messages: [...(state?.chatHistory?.messages || []), action.payload.message],
                },
            };
        case 'SET_CONVO_URL':
            return { ...state, convoUrl: action.payload };
        default:
            throw new Error(`Unhandled action type: ${(action as ChatAction).type}`);
    }
};

const initialState: ChatState = {
    refreshKey: 1,
    assistant: CASETIVITY_CHAT,
    pageContext: {},
    isOpen: false,
    reportParameters: null,
    fieldFiller: () => {},
    disableInputSendButton: false,
    prevValue: null,
    chatHistory: null,
};

const useChatBotReducer = () => {
    const [state, dispatch] = useReducer(chatBotReducer, initialState);

    const refresh = useCallback(() => {
        dispatch({ type: 'REFRESH' });
    }, []);
    const disableInputSendButton = useCallback(() => {
        dispatch({ type: 'DISABLE_INPUT_SEND_BUTTON' });
    }, []);
    const enableInputSendButton = useCallback(() => {
        dispatch({ type: 'ENABLE_INPUT_SEND_BUTTON' });
    }, []);

    const toggleChat = useCallback(() => {
        dispatch({ type: 'TOGGLE_CHAT' });
    }, []);

    const updateChatContext = useCallback((updates: Partial<ChatState>) => {
        dispatch({ type: 'UPDATE_CHAT_CONTEXT', payload: updates });
    }, []);

    const updatePageContext = useCallback((updates: Record<string, any>) => {
        dispatch({ type: 'UPDATE_PAGE_CONTEXT', payload: updates });
    }, []);

    const updateAssistantContext = useCallback(
        (newAssistant: AssistantType) => {
            if (state.assistant !== newAssistant) {
                dispatch({ type: 'UPDATE_ASSISTANT_CONTEXT', payload: newAssistant });
                dispatch({ type: 'REFRESH' });
            }
        },
        [state.assistant],
    );

    const updateReportContext = useCallback((updates: CurrentReportType) => {
        dispatch({ type: 'UPDATE_REPORT_CONTEXT', payload: updates });
    }, []);

    const updateFieldFiller = useCallback((fieldFiller: (value: string) => void) => {
        dispatch({ type: 'UPDATE_FIELD_FILLER', payload: fieldFiller });
    }, []);
    const setPrevValue = useCallback((value: string) => {
        dispatch({ type: 'SET_PREV_VALUE', payload: value });
    }, []);
    const addToHistory = useCallback((threadId: string, message: Message) => {
        dispatch({ type: 'ADD_TO_HISTORY', payload: { threadId, message } });
    }, []);
    const setConvoUrl = useCallback((url: string) => {
        dispatch({ type: 'SET_CONVO_URL', payload: url });
    }, []);

    return {
        state,
        refresh,
        toggleChat,
        updateChatContext,
        updatePageContext,
        updateAssistantContext,
        updateReportContext,
        updateFieldFiller,
        disableInputSendButton,
        enableInputSendButton,
        setPrevValue,
        addToHistory,
        setConvoUrl,
    };
};

export default useChatBotReducer;
