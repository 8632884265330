import React from 'react';
import Box from '@mui/material/Box';

const UserChatMessage = ({ message }: { message: string }) => {
    const formattedMessage = message.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line.replace(/\t/g, '\u00A0\u00A0\u00A0\u00A0')}
            <br />
        </React.Fragment>
    ));

    return (
        <Box
            sx={(theme) => ({
                padding: 2,
                backgroundColor: theme.palette.grey[200],
                borderRadius: 1,
                color: theme.palette.text.primary,
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
            })}
        >
            {formattedMessage}
        </Box>
    );
};

export default UserChatMessage;
