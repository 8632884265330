import { createGetValueSets } from 'components/generics/form/EntityFormContext/util/getEntities';
import { useMemo } from 'react';
import { useAppSelector } from 'reducers/rootReducer';

const useValueSets = () => {
    const getValuesets = useMemo(createGetValueSets, []);
    const entities = useAppSelector(getValuesets);
    return entities;
};
export default useValueSets;
