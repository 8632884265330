export const CASETIVITY_CHAT = 'casetivity-chat';
export const REPORT_BUILDER = 'report-builder';

export type AssistantType = typeof CASETIVITY_CHAT | typeof REPORT_BUILDER;

export interface Assistant {
    assistant: AssistantType;
    displayName: string;
}

const casetivityChat: Assistant = { assistant: CASETIVITY_CHAT, displayName: 'Casetivity Compass' };
const reportBuilder: Assistant = { assistant: REPORT_BUILDER, displayName: 'Report Builder' };
export const assistants: Assistant[] = [casetivityChat, reportBuilder];
//todo: think about how to restrict access to reportBuilder when not on reportBuilder page

export const REFRESH = 'REFRESH';
export const TOGGLE_CHAT = 'TOGGLE_CHAT';
export const TOGGLE_SIZE = 'TOGGLE_SIZE';
export const UPDATE_CHAT_CONTEXT = 'UPDATE_CHAT_CONTEXT';
export const UPDATE_PAGE_CONTEXT = 'UPDATE_PAGE_CONTEXT';
export const UPDATE_ASSISTANT_CONTEXT = 'UPDATE_ASSISTANT_CONTEXT';
export const UPDATE_REPORT_CONTEXT = 'UPDATE_REPORT_CONTEXT';
export const UPDATE_FIELD_FILLER = 'UPDATE_FIELD_FILLER';
export const GET_CURRENT_STATE = 'GET_CURRENT_STATE';
export const DISABLE_INPUT_SEND_BUTTON = 'DISABLE_INPUT_SEND_BUTTON';
export const ENABLE_INPUT_SEND_BUTTON = 'ENABLE_INPUT_SEND_BUTTON';
export const SET_PREV_VALUE = 'SET_PREV_VALUE';
export const SET_CONVO_URL = 'SET_CONVO_URL';
export const ADD_TO_HISTORY = 'ADD_TO_HISTORY';
