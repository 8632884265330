import { makeAdhocList } from 'mui-rest-list';
import { DecisionTable } from './domain';
import { services } from 'sideEffect/services';
import { Button, Card, TableCell, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Add from '@material-ui/icons/Add';
import { Create } from '@material-ui/icons';
import Message from 'i18n/components/Message';
import getNoResultsElement from 'components/generics/genericList/getNoResultsTextElement';
import FilterForm from './list/FilterForm';

const { AdhocList, AdhocListColumn } = makeAdhocList<DecisionTable>();

const DecisionTableList = () => {
    const theme = useTheme();
    return (
        <Card>
            <AdhocList
                type="paginated"
                pagesNIndexed={0}
                defaultSize={10}
                hasRefresh
                titleOptions={{
                    type: 'Typography',
                    text: 'Decision Tables',
                    TypographyProps: {
                        variant: 'h5',
                        style: {
                            padding: theme.spacing(2),
                        },
                    },
                }}
                renderFilter={(params) => <FilterForm {...params} />}
                renderNoResults={() => getNoResultsElement(<Message id="list.noResults" dm="No results found" />)}
                getDataObservable={({ orderBy, filter, size, page }) =>
                    services.decisionTables.list({
                        page,
                        size,
                        filter: {
                            nameLike: filter?.name ?? '',
                            keyLike: filter?.key ?? '',
                        },
                        orderBy,
                    })
                }
                tableCaption="Forms in modeler"
                initialFilter={{ name: '', key: '' }}
                TopRightAction={
                    <div style={{ marginRight: '1em' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            component={Link}
                            to="/admin/bpm/decision-table/create"
                            endIcon={<Add />}
                            size="small"
                        >
                            Create
                        </Button>
                    </div>
                }
            >
                <AdhocListColumn sortable title="name" fieldKey="name" />
                <AdhocListColumn sortable title="key" fieldKey="key" />
                <AdhocListColumn
                    title="description"
                    fieldKey="description"
                    renderdata={(desc) => {
                        return <TableCell>{desc}</TableCell>;
                    }}
                />
                <AdhocListColumn
                    title="Link"
                    hideColTitle
                    fieldKey="key"
                    renderdata={(key: string) => {
                        return (
                            <TableCell>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    endIcon={<Create />}
                                    to={`/admin/bpm/decision-table/edit/${key}`}
                                    component={Link}
                                >
                                    Edit
                                </Button>
                            </TableCell>
                        );
                    }}
                />
            </AdhocList>
        </Card>
    );
};
export default DecisionTableList;
