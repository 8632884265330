import React from 'react';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import { Button, useTheme } from '@mui/material';
import { useChatBot } from 'chatbot/ChatbotProvider';

const InputMessage = ({ handleSubmit, input, placeholder, setInputValue }) => {
    const theme = useTheme();
    const { state: chatState } = useChatBot();
    return (
        <Box
            sx={{ width: '100%', height: '100%' }}
            component="form"
            display="flex"
            alignItems="center"
            onSubmit={handleSubmit}
        >
            <TextField
                multiline
                maxRows={8}
                fullWidth
                variant="outlined"
                value={input}
                onChange={(e) => {
                    setInputValue(e.target.value);
                }}
                placeholder="Type a message..."
                sx={{ backgroundColor: theme.palette.primary.contrastText }}
            />
            <Button
                disabled={chatState.disableInputSendButton || !input.trim()}
                variant="contained"
                type="submit"
                sx={{
                    height: '100%',
                    backgroundColor: theme.palette.primary.main,
                }}
            >
                <SendIcon sx={{ height: '100%', color: theme.palette.primary.contrastText }} />
            </Button>
        </Box>
    );
};

export default InputMessage;
