import { useState, useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

const useLabelLineCount = () => {
    const labelRef = useRef<HTMLDivElement | null>(null);
    const [lineCount, setLineCount] = useState(1);

    useEffect(() => {
        const labelElement = labelRef.current;
        if (!labelElement) return;

        let resizeTimeout: NodeJS.Timeout | null = null;

        const checkLabelWrap = () => {
            // get height of label wrapped or not
            const labelHeight = labelElement.getBoundingClientRect().height;
            const computedStyle = window.getComputedStyle(labelElement);
            // get height of one line regardless of size
            const lineHeight = parseFloat(computedStyle.lineHeight);
            const calculatedLineCount = Math.ceil(labelHeight / lineHeight);
            setLineCount(calculatedLineCount);
        };

        const handleResize = () => {
            if (resizeTimeout) {
                clearTimeout(resizeTimeout);
            }
            resizeTimeout = setTimeout(() => {
                checkLabelWrap();
            }, 200);
        };

        const resizeObserver = new ResizeObserver(() => {
            handleResize();
        });

        // Lets check both resizeObserver and window resize to ensure we catch all possible changes to label
        resizeObserver.observe(labelElement);
        window.addEventListener('resize', handleResize);

        checkLabelWrap();

        return () => {
            resizeObserver.disconnect();
            window.removeEventListener('resize', handleResize);
            if (resizeTimeout) {
                clearTimeout(resizeTimeout);
            }
        };
    }, []);

    return { labelRef, lineCount };
};

export default useLabelLineCount;
