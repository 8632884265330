import { useEvaluateTemplateInFormContext } from 'expressions/hooks/allForms/useEvaluateTemplate';
import { useMemo } from 'react';

export const withTemplatableOpenToExpression = <P extends { openTo?: 'edit' | 'show' }>(
    expressionTemplate: string,
    BaseComponent: React.ComponentType<P>,
) => {
    return (props: P) => {
        const openToValue = useEvaluateTemplateInFormContext(expressionTemplate);
        const openTo: 'edit' | 'show' | undefined = useMemo(() => {
            switch (openToValue?.toLowerCase()) {
                case 'edit':
                case 'show':
                    return openToValue as 'edit' | 'show';
                default: {
                    const msg = `openToSPEL expression ${JSON.stringify(
                        expressionTemplate,
                    )} resolves to ${JSON.stringify(
                        openToValue,
                    )}, which is an unexpected value (expecting "edit" or "show").\nFalling back to default behavior.`;
                    console.error(msg);
                    return undefined;
                }
            }
        }, [openToValue]);
        return <BaseComponent {...props} openTo={openTo} />;
    };
};
