import React, { useMemo, FunctionComponent } from 'react';
import { Button, TextField } from '@material-ui/core';
import useViewConfig, { OverriddenViewConfigContext } from 'util/hooks/useViewConfig';
import produce from 'immer';
import { View } from 'reducers/ViewConfigType';
import { FormProvider, useForm } from 'react-hook-form';
import NavWarning from 'layout-editor/build-layout/steps/components/NavWarning';
import PopoverRefInput from 'fieldFactory/popovers/PopoverRefInput';
import { createMrgListView } from 'injectViews/injectMatchViewsFromMrgViews';
import NavigateNext from '@material-ui/icons/NavigateNext';
import Unsplit from 'components/generics/genericUnsplit/Unsplit';
import { EntityViewConfig } from 'expressions/entityViewConfig/type';

interface Step3SplitProps {
    initialValues: Partial<View>;
    onSubmit: (action: {
        type: 'replace' | 'write';
        payload: Pick<View, 'name' | 'entity' | 'viewType' | 'route'>;
    }) => void;
}
const Step3Split: FunctionComponent<Step3SplitProps> = (props) => {
    const { initialValues, onSubmit: _onSubmit } = props;
    const methods = useForm({
        defaultValues: initialValues,
        mode: 'onBlur',
    });
    const { handleSubmit, watch } = methods;
    const view = watch() as View;
    React.useEffect(() => {
        methods.register({ name: 'splitRecordId' });
        methods.register({ name: 'config' });
        return () => {
            methods.unregister('splitRecordId');
            methods.unregister('config');
        };
    }, []); // eslint-disable-line
    const onSubmit = ({ splitRecordId, ...data }: any) => {
        _onSubmit({ type: 'write', payload: { ...initialValues, ...data } });
    };
    const viewConfig = useViewConfig();

    const overrideViewConfig = useMemo(() => {
        /*
            Now that we have our match view, we need to generate the MrgList view from it.
        */
        return produce(viewConfig, (draft) => {
            const newMatchView = {
                ...initialValues,
                ...view,
            } as View;
            draft.views[initialValues.name] = newMatchView;
            const newMrgListView = createMrgListView(newMatchView);
            draft.views[newMrgListView.name] = newMrgListView;
            return draft;
        });
    }, [viewConfig, view, initialValues]);
    const value = watch('splitRecordId');
    if (
        !initialValues.viewType ||
        !initialValues.entity ||
        !initialValues.name ||
        !overrideViewConfig.views[initialValues.name]
    ) {
        return <div>Please fill out step 1.</div>;
    }

    return (
        <div>
            <FormProvider {...methods}>
                <div style={{ padding: '1em', margin: '1em' }}>
                    <h2>Step 3: Review and Configure Expressions</h2>
                    <NavWarning />
                    <PopoverRefInput
                        label="Select record"
                        resource={initialValues.entity}
                        reference={initialValues.entity}
                        source="splitRecordId"
                        options={{}}
                        input={{
                            value,
                            onBlur: (v) => {
                                if (typeof v !== 'undefined') {
                                    methods.setValue('splitRecordId', v || null, {
                                        shouldDirty: true,
                                        shouldValidate: true,
                                    });
                                }
                            },
                        }}
                        meta={{}}
                    />

                    <OverriddenViewConfigContext.Provider value={overrideViewConfig}>
                        {value && initialValues.name && (
                            <>
                                {(() => {
                                    const config = JSON.parse(view?.config || null) as EntityViewConfig;

                                    return (
                                        <div style={{ margin: '1em' }}>
                                            <TextField
                                                label="Redirect to (on split)"
                                                helperText="Location to redirect to on success (optional). Template SPEL using $[] tags. Access records via 'primaryRecord' or 'secondaryRecord'. E.g '/User/$[secondaryRecord.createdBy.id]'"
                                                value={config?.redirectOnSplit ?? ''}
                                                onChange={(e) => {
                                                    methods.setValue(
                                                        'config',
                                                        JSON.stringify({
                                                            ...config,
                                                            redirectOnSplit: e.target.value || undefined,
                                                        } as EntityViewConfig),
                                                    );
                                                }}
                                            />
                                        </div>
                                    );
                                })()}
                                <Unsplit
                                    id={value as string}
                                    entityType={initialValues.entity}
                                    overrideViewName={initialValues.name}
                                />
                            </>
                        )}
                    </OverriddenViewConfigContext.Provider>

                    <div style={{ marginTop: '1em', paddingTop: '1em', width: '100%', textAlign: 'right' }}>
                        <Button id="step3-submit" variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                            Submit&nbsp;
                            <NavigateNext />
                        </Button>
                    </div>
                </div>
            </FormProvider>
        </div>
    );
};

export default Step3Split;
