import React, { FunctionComponent, useCallback, useContext } from 'react';
import { Route, Link } from 'react-router-dom';
import { StateMachineProvider, createStore } from 'little-state-machine';
import Step0 from 'layout-editor/build-layout/steps/Step0';
import { defaultState, ViewWizardAction } from 'layout-editor/build-layout/viewWizardReducer';
import 'layout-editor/build-layout/wizardTabs.css';
import { View } from 'reducers/ViewConfigType';
import useViewWizardState from 'layout-editor/build-layout/hooks/useViewWizardState';
import { Card, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Step1 from 'layout-editor/UniversalViewWizard/steps/Step1';
import Step2CreateEditShow from 'layout-editor/UniversalViewWizard/steps/Step2CreateEditShow';
import Step2List from 'layout-editor/UniversalViewWizard/steps/Step2List';
import Step2Merge from 'layout-editor/UniversalViewWizard/steps/Step2Merge';
import Step2Match from 'layout-editor/UniversalViewWizard/steps/Step2Match';
import Step3CreateEditShow from 'layout-editor/UniversalViewWizard/steps/Step3CreateEditShow';
import Step3List from 'layout-editor/UniversalViewWizard/steps/Step3List';
import Step3Merge from 'layout-editor/UniversalViewWizard/steps/Step3Merge';
import Step3Match from 'layout-editor/UniversalViewWizard/steps/Step3Match';
import Step4List from 'layout-editor/UniversalViewWizard/steps/Step4List';
import Result from 'layout-editor/UniversalViewWizard/steps/Result';
import useViewConfig from 'util/hooks/useViewConfig';
import Copy from '@material-ui/icons/FileCopy';
import { viewConfigNeedsRefreshContext } from 'components/layouts/ViewConfigNeedsRefresh/ViewConfigNeedsRefresh';
import produce from 'immer';
import { RootState, useAppStore } from 'reducers/rootReducer';
import { loadSuccess } from 'viewConfig/actions';
import { viewToViewDef } from 'layout-editor/hooks/useSubmitView';
import Alert from '@material-ui/lab/Alert';
import { useUserCanEditViewDefs } from './useUserCanEditViewDefs';
import Step3Split from 'layout-editor/UniversalViewWizard/steps/Step3Split';
import { Typography } from '@mui/material';

const ROOT = '/view-definition-editor';

const getSteps: (n: 3 | 4) => {
    [currPath: string]: { prev?: string; next?: string };
} = (n) => {
    const res = {
        [ROOT]: { next: ROOT + '/step1' },
        [ROOT + '/step1']: { next: ROOT + '/step2', prev: ROOT },
        [ROOT + '/step2']: { next: ROOT + '/step3', prev: ROOT + '/step1' },
        [ROOT + '/step3']: { next: ROOT + (n === 4 ? '/step4' : '/result'), prev: ROOT + '/step2' },
        [ROOT + '/step4']: { next: ROOT + '/result', prev: ROOT + '/step3' },
        [ROOT + '/result']: { prev: ROOT + (n === 4 ? '/step4' : '/step3') },
    };
    if (n === 4) {
        res[ROOT + '/step4'] = { next: ROOT + '/result', prev: ROOT + '/step3' };
    }
    return res;
};
const Step0Component = (props) => (
    <Step0
        viewTypes={['EDIT', 'CREATE', 'SHOW', 'LIST', 'MATCH', 'MERGE', 'COMPONENT']}
        nextLocation={getSteps(3)[ROOT].next}
    />
);

export interface StepProps {
    initialValues: Partial<View>;
    onSubmit: (action: {
        type: 'replace' | 'write';
        payload: Pick<View, 'name' | 'entity' | 'viewType' | 'route'>;
    }) => void;
}
interface WizardStepProps {
    Component: React.ComponentType<StepProps>;
}
const WizardStep: FunctionComponent<WizardStepProps> = ({ Component }) => {
    const { state, action } = useViewWizardState();
    const { push, location } = useHistory();

    return (
        <Card style={{ padding: '1em', margin: '1em' }}>
            <Component
                initialValues={state.viewData as Partial<View>}
                onSubmit={(_action, navigate = true) => {
                    action(_action as ViewWizardAction);
                    if (navigate) {
                        push(getSteps(state.viewData.viewType === 'LIST' ? 4 : 3)[location.pathname].next);
                    }
                }}
            />
        </Card>
    );
};
const getWizardStep = (BaseComponent: React.ComponentType<StepProps>) => (props) => {
    return <WizardStep Component={BaseComponent} />;
};

interface BuildLayoutProps {
    initialValues;
    location: Location;
}
createStore(defaultState);

interface ActionsProps {
    refresh: () => void;
}
const Actions: FunctionComponent<ActionsProps> = ({ refresh }) => {
    const { state, action } = useViewWizardState();
    const createAsCopy = useCallback(() => {
        if (state.meta && state.meta['existingViewName'] && state.meta['existingViewName'] === state.viewData.name) {
            let newViewName = '';
            do {
                newViewName = prompt('Please provide a new (unique) name for the view', state.viewData.name);
                if (newViewName === null) {
                    // user clicked 'cancel'
                    return;
                }
            } while (!newViewName || newViewName === state.viewData.name);
            action({
                type: 'write',
                payload: {
                    name: newViewName,
                },
            });
        }
        action({
            type: 'eraseMeta',
        });
        refresh();
    }, [action, state, refresh]);
    if (state.meta && state.meta['existingViewName']) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row-reverse', margin: '1em' }}>
                <Button onClick={createAsCopy} color="primary" variant="contained">
                    Create view as copy&nbsp;
                    <Copy />
                </Button>
            </div>
        );
    }
    return null;
};
const NavTabs = (props) => {
    const { location } = props;
    const { state } = useViewWizardState();
    const viewCategory =
        state.viewData.viewType === 'EDIT' ||
        state.viewData.viewType === 'CREATE' ||
        state.viewData.viewType === 'SHOW' ||
        state.viewData.viewType === 'COMPONENT'
            ? 'ecs'
            : state.viewData.viewType === 'LIST'
            ? 'list'
            : state.viewData.viewType === 'MERGE'
            ? 'mrg'
            : state.viewData.viewType === 'SPLIT'
            ? 'split'
            : state.viewData.viewType === 'MATCH'
            ? 'match'
            : 'none';
    return (
        <ul className="wzsteps">
            <li className={location.pathname === '/view-definition-editor' ? 'wzactive' : ''}>
                <Link to="/view-definition-editor">Pick existing</Link>
            </li>
            <li className={location.pathname === '/view-definition-editor/step1' ? 'wzactive' : ''}>
                <Link to="/view-definition-editor/step1">Step 1</Link>
            </li>
            {viewCategory !== 'none' ? (
                <React.Fragment>
                    <li className={location.pathname === '/view-definition-editor/step2' ? 'wzactive' : ''}>
                        <Link to="/view-definition-editor/step2">Step2</Link>
                    </li>
                    <li className={location.pathname === '/view-definition-editor/step3' ? 'wzactive' : ''}>
                        <Link to="/view-definition-editor/step3">Step3</Link>
                    </li>
                    {viewCategory === 'list' ? (
                        <li className={location.pathname === '/view-definition-editor/step4' ? 'wzactive' : ''}>
                            <Link to="/view-definition-editor/step4">Step4</Link>
                        </li>
                    ) : null}
                    <li className={location.pathname === '/view-definition-editor/result' ? 'wzactive' : ''}>
                        <Link to="/view-definition-editor/result">Result</Link>
                    </li>
                </React.Fragment>
            ) : null}
        </ul>
    );
};
const Step2CES = getWizardStep(Step2CreateEditShow);
const Step2L = getWizardStep(Step2List);
const Step2Mg = getWizardStep(Step2Merge);
const Step2Mch = getWizardStep(Step2Match);
const Step3Splt = getWizardStep(Step3Split);
const Step2 = (props) => {
    const { state } = useViewWizardState();
    switch (state.viewData.viewType) {
        case 'CREATE':
        case 'EDIT':
        case 'SHOW':
        case 'COMPONENT':
            return <Step2CES {...props} />;
        case 'LIST':
            return <Step2L {...props} />;
        case 'MATCH':
            return <Step2Mch {...props} />;
        case 'SPLIT':
        case 'MERGE':
            return <Step2Mg {...props} />;
        default:
            return null;
    }
};
const Step3CES = getWizardStep(Step3CreateEditShow);
const Step3L = getWizardStep(Step3List);
const Step3Mg = getWizardStep(Step3Merge);
const Step3Mch = getWizardStep(Step3Match);
const Step3 = (props) => {
    const { state } = useViewWizardState();
    switch (state.viewData.viewType) {
        case 'CREATE':
        case 'EDIT':
        case 'SHOW':
        case 'COMPONENT':
            return <Step3CES {...props} />;
        case 'LIST':
            return <Step3L {...props} />;
        case 'MATCH':
            return <Step3Mch {...props} />;
        case 'SPLIT':
            return <Step3Splt {...props} />;
        case 'MERGE':
            return <Step3Mg {...props} />;
        default:
            return null;
    }
};

const Step4L = getWizardStep(Step4List);
const Step4 = (props) => {
    const { state } = useViewWizardState();
    switch (state.viewData.viewType) {
        case 'LIST':
            return <Step4L {...props} />;
        default:
            return null;
    }
};

const ResultW = (props) => {
    const { state, action } = useViewWizardState();
    const viewConfig = useViewConfig();
    const existsAsViewDef = Boolean(viewConfig.viewDefs && viewConfig.viewDefs[state.viewData.name]);
    const viewData = state.viewData;
    const { setViewConfigNeedsRefresh } = useContext(viewConfigNeedsRefreshContext);
    const store = useAppStore();
    const setAsExisting = useCallback(() => {
        const viewDef = viewToViewDef(viewData as View);
        const mergedViewConfig = produce((store.getState() as RootState).viewConfig, (draft) => {
            const newEntry = {
                entity: viewData.entity,
                entityRevision: draft.viewDefs[props.initialValues.name]?.entityRevision
                    ? draft.viewDefs[props.initialValues.name].entityRevision + 1
                    : 1,
                name: viewData.name,
                definition: JSON.stringify(viewDef),
            };
            draft.viewDefs[viewData.name] = newEntry;
            if (props.initialValues.name !== viewData.name) {
                delete draft[props.initialValues.name];
            }
        });
        store.dispatch(loadSuccess(mergedViewConfig));
        action({
            type: 'pickExisting',
            meta: {
                existingViewName: viewData.name,
            },
            viewData: {
                ...viewData,
                entityVersion: (viewData.entityVersion ?? 0) + 1,
            },
        });
        setTimeout(() => {
            setViewConfigNeedsRefresh(true);
        }, 20 * 1000);
    }, [action, viewData, setViewConfigNeedsRefresh, store, props.initialValues.name]);
    const exists = Boolean(state.meta && (state.meta as { existingViewName: string }).existingViewName);
    const diffFrom = existsAsViewDef && exists ? 'viewDef if exists' : state.meta ? 'entityview if exists' : 'none';
    if (exists) {
        return (
            <Result
                onSuccess={setAsExisting}
                initialValues={props.initialValues}
                mode="PUT"
                prevViewName={(state.meta as { existingViewName?: string }).existingViewName}
                diffFrom={diffFrom}
            />
        );
    }
    return <Result onSuccess={setAsExisting} initialValues={props.initialValues} mode="POST" diffFrom={diffFrom} />;
};
const ResultWizardStep = getWizardStep(ResultW);
const MultiPageViewDefEditor: React.FunctionComponent<BuildLayoutProps> = (props) => {
    const { location } = props;
    const [refreshKey, setRefreshKey] = React.useState<number>(1);
    const refresh = useCallback(() => setRefreshKey(refreshKey + 1), [setRefreshKey, refreshKey]);
    const hasPermission = useUserCanEditViewDefs();
    if (!hasPermission) {
        return <Alert severity="warning">User does not have permissions to access View Definition Editor.</Alert>;
    }
    return (
        <div>
            <Typography component={'h1'} variant="h4" gutterBottom>
                View Definition Editor
            </Typography>
            <StateMachineProvider>
                <nav className="container">
                    <NavTabs location={location} />
                </nav>
                {/* Lets hide actions on opening page because we might still have a record loaded and don't want to confuse user */}
                {location.pathname.endsWith('view-definition-editor') ? null : <Actions refresh={refresh} />}
                <React.Fragment key={refreshKey}>
                    <Route exact path="/view-definition-editor" component={Step0Component} />
                    <Route path="/view-definition-editor/step1" component={getWizardStep(Step1)} />
                    <Route path="/view-definition-editor/step2" component={Step2} />
                    <Route path="/view-definition-editor/step3" component={Step3} />
                    <Route path="/view-definition-editor/step4" component={Step4} />
                    <Route path="/view-definition-editor/result" component={ResultWizardStep} />
                </React.Fragment>
            </StateMachineProvider>
        </div>
    );
};

export default MultiPageViewDefEditor;
