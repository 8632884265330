import { createSelector } from 'reselect';
import { RootState } from '../../../../reducers/rootReducer';
import getAdhocFieldsExpected, { getAdhocFieldsForView, EXTERNALGISID } from './util/getAllFieldsExpected';
import createRecordSelector, { visibilityExpressionsSelector } from './util/recordSelector';
import forceBooleanFieldsBooleanAndArrayFieldsArrays from './util/enforceBoolAndArrayValues';
import { getAllValuesetFields, getExpressions } from './util/entityVisExp';
import createFormContext from './util/createFormContext';
import { createGetEntities, createGetValueSets } from './util/getEntities';
import createDeepEqlSelector from './util/createDeepEqlSelector';
import mapFieldsToWidgets from './util/mapFieldsToWidgets';
import { CasetivityConfigRootViewContextVariable } from 'util/casetivityViewContext';
import getProviderAndHocWithViewContext from './util/getProviderAndHocWithViewContext';
import { EntityVisibilityExps } from 'reducers/entityVisibilityReducer';
import { FormContextEvaluator } from 'expressions/CachingEvaluator/FormContextEvaluator';
import ViewConfig from 'reducers/ViewConfigType';
import getAdhocVariablesContextSelector, { nullAdhocVariablesContext } from './util/getVariablesContextSelector';
import { EntityFormContextRef } from 'bpm/components/TaskDetail/TaskForm/TaskForm/types';
import { EntityFieldConceptExps } from 'viewConfigCalculations/ConceptAvailabilityExpressions/EntityFieldConceptExps';
import merge from 'lodash/merge';
import { ViewCalcValueExpressionMetaData } from 'expression-tester/entity-form/CalcValueConfiguration/ViewCalcValueExpressions';
import { sortVariablesByDependencies } from '.';
import { ConditionalDefaultValueExpressionMetaData } from 'expression-tester/entity-form/ConditionalDefaultValueConfiguration/ConditionalDefaultValueExpressions';
import { ViewDefaultValueExpressionMetaData } from 'expression-tester/entity-form/DefaultValueConfiguration/ViewDefaultValueExpressions';
import { getValueSetFieldsRequiredForEntity } from 'components/generics/utils/viewConfigUtils';
export interface FCPProps {
    viewName: string;
    record: { id: string; entityType: string };
    overrideViewConfig?: ViewConfig;
    viewContext: CasetivityConfigRootViewContextVariable;
    formId?: undefined;
    overrides?: {
        visibilityExps?: EntityVisibilityExps[0];
        conceptExps?: EntityFieldConceptExps[0];
        calcExps?: ViewCalcValueExpressionMetaData[0];
        defaultExps?: ViewDefaultValueExpressionMetaData[0];
        conditionalDefaultExps?: ConditionalDefaultValueExpressionMetaData[0];
    };
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
    entityFormContextRef?: EntityFormContextRef;
    patchValues?: Record<string, unknown>;
    disableNullPointerExceptions?: true;
}

const emptyObj = {};

const conceptExpressionsSelector = (state: RootState, props: FCPProps): EntityFieldConceptExps[0] =>
    props.overrides?.conceptExps || state.entityConceptExps[props.viewName] || emptyObj;

const calcExpressionsSelector = (state: RootState, props: FCPProps): ViewCalcValueExpressionMetaData[0] =>
    props.overrides?.calcExps || state.viewCalcValueExpressions[props.viewName] || emptyObj;

const conditionalDefaultExpsSelector = (
    state: RootState,
    props: FCPProps,
): ConditionalDefaultValueExpressionMetaData[0] =>
    props.overrides?.conditionalDefaultExps || state.conditionalDefaultValueExpressions[props.viewName] || emptyObj;

const defaultExpsSelector = (state: RootState, props: FCPProps): ViewDefaultValueExpressionMetaData[0] =>
    props.overrides?.defaultExps || state.viewDefaultValueExpressions[props.viewName] || emptyObj;

const createFormContextSelector = () => {
    const getEntities = createGetEntities();
    const recordSelector = createRecordSelector();
    const getValueSets = createGetValueSets();
    const formContextEvaluatorSelector = createSelector(
        visibilityExpressionsSelector,
        conceptExpressionsSelector,
        calcExpressionsSelector,
        conditionalDefaultExpsSelector,
        defaultExpsSelector,
        (state: RootState, props: FCPProps) => props.overrideViewConfig || state.viewConfig,
        (state: RootState, props: FCPProps) => props.viewName,
        (state: RootState, props: FCPProps) => props.viewContext,
        (state: RootState, props: FCPProps) => state.printMode,
        (state: RootState, props: FCPProps) => props.disableNullPointerExceptions,
        (
            visConfig,
            conceptConfig,
            calcExps,
            conditionalDefaultExps,
            defaultExps,
            viewConfig,
            viewName,
            viewContext,
            printMode,
            disableNullPointerExceptions,
        ) => {
            const adhocFieldsExpected = getAdhocFieldsExpected(viewConfig, viewName, {}, getAdhocFieldsForView);
            const fieldsToWidgets = mapFieldsToWidgets(viewConfig, viewName, adhocFieldsExpected, printMode);

            const allValuesetFields = {
                ...getValueSetFieldsRequiredForEntity(viewConfig, viewName, 'ONES'),
                ...getAllValuesetFields(visConfig, {}, {}, {}, calcExps, defaultExps, conditionalDefaultExps),
            };
            const visibilityExpressions = getExpressions(visConfig);
            const conceptExpressions = Object.fromEntries(
                Object.values(conceptConfig).map((ca) => [ca.fieldName, ca.expression]),
            );
            const { variablesInExecutionOrder, remainingVariables } = sortVariablesByDependencies(calcExps);
            if (remainingVariables.length > 0) {
                alert('A cyclic dependency was discovered in the Calculated Expressions logged to the console.');
                console.log('A cyclic dependency was discovered in the following variables:', remainingVariables);
            }
            const variables = variablesInExecutionOrder.map((group) =>
                group.reduce((prev, curr) => {
                    prev[curr.fieldName] = curr.expression;
                    return prev;
                }, {} as { [varName: string]: string }),
            );
            return new FormContextEvaluator({
                basedOnEntityOptions: null,
                evaluationFactors: {
                    fieldWidgets: fieldsToWidgets,
                    dropdownAvailableOptionsExpressions: {},
                    valueset1AvailableConceptsExpressions: conceptExpressions,
                    valueset1Fields: allValuesetFields,
                    visibilityExpressions,
                    editabilityExpressions: {},
                    tableExpressions: {},
                    useBackingValuesRegardlessOfDisplayStatus: {
                        [EXTERNALGISID]: true,
                    },
                    variables,
                },
                options: {
                    viewContext,
                    dateFormat: (viewConfig && viewConfig.application && viewConfig.application.dateFormat) || '',
                },
                viewConfig,
                disableNullPointerExceptions,
            });
        },
    );

    const adhocVariablesContextSelector = getAdhocVariablesContextSelector();

    const includeAdhocFieldsSelector = createSelector(
        (state: RootState, props: FCPProps) => props.overrideViewConfig || state.viewConfig,
        (state: RootState, props: FCPProps) => props.viewName,
        (viewConfig, viewName) => {
            return getAdhocFieldsForView(viewConfig.views[viewName]);
        },
    );
    const formContextSelector = createSelector(
        formContextEvaluatorSelector,
        (state: RootState, props: FCPProps) => recordSelector(state, props, includeAdhocFieldsSelector(state, props)),
        getEntities,
        getValueSets,
        (state: RootState, props: FCPProps) => props.overrideViewConfig || state.viewConfig,
        (state: RootState, props: FCPProps) => props.viewName,
        (state: RootState, props: FCPProps) => adhocVariablesContextSelector(props.evaluatedAdhocSPELVariables),
        (state: RootState, props: FCPProps) => props.entityFormContextRef,
        (state: RootState, props: FCPProps) => state.printMode,
        (state: RootState, props: FCPProps) => props.patchValues,
        (
            formContextEvaluator,
            values: {},
            entities: {},
            valueSets,
            viewConfig,
            viewName,
            adhocVariablesContext,
            entityFormContextRef,
            printMode,
            patchValues,
        ) => {
            const _valuesToUse = forceBooleanFieldsBooleanAndArrayFieldsArrays(viewConfig, viewName)(values);

            const valuesToUse = merge({}, _valuesToUse, patchValues);

            const { hiddenFields, fieldValues, valuesetFieldAvailableConceptIds, variables } =
                formContextEvaluator.evaluate(valuesToUse, valueSets, valuesToUse, entities, {
                    ...adhocVariablesContext,
                    isPrintMode: () => !!printMode,
                    getCurrentViewType: () => viewConfig.views[viewName]?.viewType ?? null,
                });
            const res = {
                hiddenFields,
                fieldValues,
                viewName,
                adhocVariablesContext,
                valuesetFieldAvailableConceptIds,
                variables,
            };
            if (entityFormContextRef) entityFormContextRef.current = res;
            return res;
        },
    );
    return createDeepEqlSelector(formContextSelector);
};

export type ShowFormContext = ReturnType<ReturnType<typeof createFormContextSelector>>;
const defaultContext: ShowFormContext = {
    hiddenFields: {},
    fieldValues: {},
    viewName: undefined,
    valuesetFieldAvailableConceptIds: {},
    adhocVariablesContext: nullAdhocVariablesContext,
    variables: {},
};

const { formContext, FormContextProvider: FCP } = createFormContext(createFormContextSelector, defaultContext);

const { FormContextProvider, formContextHoc } = getProviderAndHocWithViewContext(FCP);
export { formContext, formContextHoc, FormContextProvider };
