import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import Alert from '@material-ui/lab/Alert';
import { getUseAppBannerHtmlSelector } from 'util/applicationConfig';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
const useStyles = makeStyles((theme) =>
    createStyles({
        message: {
            flex: 1,
        },
    }),
);
const SwitchToInstalledAppBanner = () => {
    const classes = useStyles();
    const overrideUseAppBannerHtml = useAppSelector(getUseAppBannerHtmlSelector);
    const _numOfflineTasks = useAppSelector((state: RootState) =>
        !state.offlineTasks ? 0 : Object.keys(state.offlineTasks).length,
    );

    if (_numOfflineTasks === 0) {
        return null;
    }
    const defaultMessage = 'You have offline work saved locally. To resume or submit, switch to the desktop app.';
    return (
        <div>
            <Alert severity="info" classes={classes}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div>
                        {overrideUseAppBannerHtml ? (
                            <SafeHtmlAsReact html={overrideUseAppBannerHtml} />
                        ) : (
                            defaultMessage
                        )}
                    </div>
                    <div />
                </div>
            </Alert>
        </div>
    );
};

export default SwitchToInstalledAppBanner;
