import { createSelector } from 'reselect';
import { RootState } from '../../../reducers/rootReducer';
import { EntityFieldConceptExps } from 'viewConfigCalculations/ConceptAvailabilityExpressions/EntityFieldConceptExps';
import { EntityVisibilityExps } from 'reducers/entityVisibilityReducer';
import { getAllValuesetFields } from '../form/EntityFormContext/util/entityVisExp';
import { CasetivityConfigRootViewContextVariable } from 'util/casetivityViewContext';
import { ViewEditableExps } from 'reducers/entityEditabilityReducer';
import { ViewItemFilterExpressionsGeneratedType } from 'viewConfigCalculations/filterExpressions/ViewItemFilterExpressionsGeneratedType';
import { FormContextEvaluator } from 'expressions/CachingEvaluator/FormContextEvaluator';
import ViewConfig from 'reducers/ViewConfigType';
import { EntityFormContextRef } from 'bpm/components/TaskDetail/TaskForm/TaskForm/types';
import { createGetEntities, createGetValueSets } from '../form/EntityFormContext/util/getEntities';
import { cloneDeep, get, set } from 'lodash';
import createDeepEqlSelector from '../form/EntityFormContext/util/createDeepEqlSelector';
import createFormContext from '../form/EntityFormContext/util/createFormContext';
import getProviderAndHocWithViewContext from '../form/EntityFormContext/util/getProviderAndHocWithViewContext';
import { formContext as editFormContext } from 'components/generics/form/EntityFormContext';
import formTypeContext from '../form/formTypeContext';
import { ContextType } from 'react';
export interface FCPProps {
    formId: string;
    record: {
        // because we use this for create, might be empty
        // id?: string;
        entityType?: string;
    };
    overrideViewConfig?: ViewConfig;
    overrides?: {
        visibilityExps?: EntityVisibilityExps[0];
        editableExps?: ViewEditableExps[0];
        conceptExps?: EntityFieldConceptExps[0];
        filterExps?: ViewItemFilterExpressionsGeneratedType[0];
    };
    entityFormContextRef?: EntityFormContextRef;
    viewContext: CasetivityConfigRootViewContextVariable;
    viewName?: string;
}

const getExpressionsSelector = <MultipleRecordRoots extends string>() => {
    return createSelector(
        (state: RootState, props: FCPProps): EntityVisibilityExps[0] =>
            (props.overrides && props.overrides.visibilityExps) || emptyObj,
        (state: RootState, props: FCPProps): EntityFieldConceptExps[0] =>
            (props.overrides && props.overrides.conceptExps) || emptyObj,
        (state: RootState, props: FCPProps): ViewEditableExps[0] =>
            (props.overrides && props.overrides.editableExps) || emptyObj,
        (state: RootState, props: FCPProps): ViewItemFilterExpressionsGeneratedType[0] =>
            (props.overrides && props.overrides.filterExps) || emptyObj,
        (visibilityConfig, entityConceptExps, viewFieldEditability, viewItemFilterExps) => {
            return {
                visibilityConfig,
                entityConceptExps,
                viewFieldEditability,
                viewItemFilterExps,
            };
        },
    );
};

const getFormContextEvaluatorSelector = <MultipleRecordRoots extends string>() => {
    const expressionsSelector = getExpressionsSelector();
    return createSelector(
        expressionsSelector,
        (state: RootState, props: FCPProps) => props.overrideViewConfig || state.viewConfig,
        (state: RootState, props: FCPProps) => state.entityValidations,
        (state: RootState, props: FCPProps) => props.record.entityType,
        (expressions, viewConfig, entityValidations, entityType) => {
            const { visibilityConfig, entityConceptExps, viewFieldEditability, viewItemFilterExps } = expressions;
            const fields = Object.values(viewConfig.entities[entityType].fields);
            const fieldWidgets: {
                [field: string]: string[];
            } = fields.reduce((prev, curr) => {
                const fieldPath = (() => {
                    switch (curr.dataType) {
                        case 'REFONE':
                        case 'VALUESET':
                            return `${curr.name}Id`;
                        case 'REFMANY':
                        case 'REFMANYMANY':
                        case 'VALUESETMANY':
                            return `${curr.name}Ids`;
                        default:
                            return curr.name;
                    }
                })();
                prev[fieldPath] = [fieldPath];
                return prev;
            }, {});

            const allValueset1Fields = {
                ...fields
                    .filter((f) => f.dataType === 'VALUESET')
                    .reduce((prev, curr) => {
                        const fieldPath = curr.name;
                        prev[fieldPath] = curr.valueSet;
                        return prev;
                    }, {}),
                ...Object.fromEntries(
                    Object.entries(
                        getAllValuesetFields(
                            visibilityConfig,
                            viewFieldEditability,
                            entityConceptExps,
                            viewItemFilterExps,
                            {},
                            {},
                            {},
                        ),
                    ),
                ),
            };
            // TODO: the below will need to be mapped onto the multiple record roots.
            // do that if we ever need these.

            // const visibilityExpressions = getExpressions(visibilityConfig);
            // const editabilityExpressions = getExpressions(viewFieldEditability);

            // const fieldsUsedInExpressions = uniq([
            //     ...Object.values(visibilityConfig).flatMap((c) => c.flatMap((cc) => cc.dataPaths)),
            //     ...Object.values(viewFieldEditability).flatMap((c) => c.flatMap((cc) => cc.dataPaths)),
            //     ...Object.values(entityValidations[entityType] || {}).flatMap((c) => c.dataPaths),
            //     ...Object.values(viewItemFilterExps || {}).flatMap((c) => c.dataPaths),
            //     ...Object.values(entityConceptExps || {}).flatMap((c) => c.dataPaths),
            // ]);

            // const bypassFilterConsistency: {
            //     [field: string]: true;
            // } = {}; // can read in from somewhere else in the future.
            // const reference1EntityFilterExpressions =
            //     viewItemFilterExps &&
            //     fromEntries(
            //         Object.entries(viewItemFilterExps)
            //             .filter(([field]) => {
            //                 const adjustedFieldName = field.endsWith('Id') ? field.slice(0, -2) : field;
            //                 return !bypassFilterConsistency[adjustedFieldName];
            //             })
            //             .map(([field, e]) => {
            //                 return [
            //                     field,
            //                     {
            //                         entityType: e.searchEntity,
            //                         expression: e.expression,
            //                     },
            //                 ] as [string, { entityType: string; expression: string }];
            //             }),
            //     );

            return new FormContextEvaluator({
                basedOnEntityOptions: null,
                evaluationFactors: {
                    fieldWidgets,
                    dropdownAvailableOptionsExpressions: {},
                    valueset1AvailableConceptsExpressions: Object.assign(
                        {},
                        ...Object.values(entityConceptExps).map((ca) => ({ [ca.fieldName]: ca.expression })),
                    ),
                    valueset1Fields: allValueset1Fields,
                    visibilityExpressions: {},
                    editabilityExpressions: {},
                    tableExpressions: {},
                    reference1EntityFilterExpressions: {},
                    useBackingValuesRegardlessOfDisplayStatus: {},
                    nullWhenHidden: {},
                    nullWhenDisabled: {},
                    dontAdjustValueBasedOnConceptExpressions: {},
                },
                options: {
                    viewContext: 'ENTITY',
                    dateFormat: viewConfig?.application?.dateFormat || '',
                },
                viewConfig,
            });
        },
    );
};
const emptyObj = {};

const setupEmptyValues = (entityType: string, viewConfig: ViewConfig, values: Record<string, unknown>) => {
    const newValues = values ? cloneDeep(values) : {};
    Object.values(viewConfig.entities[entityType].fields).forEach((f) => {
        const emptyValue = (() => {
            switch (f.dataType) {
                case 'BOOLEAN':
                    return false;
                case 'REFMANY':
                case 'REFMANYMANY':
                case 'VALUESETMANY':
                    return [];
                case 'REFONE':
                case 'VALUESET':
                    return null;
                case 'STRING':
                case 'TEXTBLOB':
                    return '';
                default:
                    return null;
            }
        })();
        const path = f.name;
        if (typeof get(newValues, path) === 'undefined') {
            set(newValues, path, emptyValue);
        }
    });
    return newValues;
};
const createFormContextSelector = () => {
    const getEntities = createGetEntities();
    const getValueSets = createGetValueSets();
    const formContextEvaluatorSelector = getFormContextEvaluatorSelector();
    const formContextSelector = createSelector(
        formContextEvaluatorSelector,
        (state: RootState, props: FCPProps) => props.overrideViewConfig || state.viewConfig,
        (state: RootState, props: FCPProps) => props.record.entityType,
        (state: RootState, props: FCPProps) => state.form![props.formId]?.initial,
        (state: RootState, props: FCPProps) => state.form![props.formId]?.values,
        getEntities,
        getValueSets,
        (state: RootState, props: FCPProps) => props.entityFormContextRef,
        (state: RootState, props: FCPProps) => props.viewName,
        (
            formContextEvaluator,
            viewConfig,
            entityType,
            initial: {},
            values: {},
            entities: {},
            valueSets,
            entityFormContextRef,
            viewName,
        ) => {
            const result = formContextEvaluator.evaluate(
                setupEmptyValues(entityType, viewConfig, values),
                valueSets,
                setupEmptyValues(entityType, viewConfig, initial),
                entities,
            );
            const { availableOptions, tableRowContexts, ...rest } = { ...result, viewName };

            if (entityFormContextRef) entityFormContextRef.current = rest;
            return rest;
        },
    );

    return createDeepEqlSelector(formContextSelector);
};

export type EntityFormContext = ReturnType<ReturnType<typeof createFormContextSelector>>;
export const defaultFormContext: EntityFormContext = {
    variables: {},
    hiddenFields: {},
    disabledFields: {},
    fieldValues: {},
    registeredValues: {},
    visibleAndEditableFields: [],
    isDirty: false,
    dirtyValues: {},
    initialValues: {},
    nullFilteredRefOneFields: [],
    valuesetFieldAvailableConceptIds: {},
    initialFormContext: undefined,
    viewName: undefined,
};

const { formContext, FormContextProvider: _EntityFormContextProvider } = createFormContext(
    createFormContextSelector,
    defaultFormContext,
);

const { FormContextProvider, formContextHoc } = getProviderAndHocWithViewContext(_EntityFormContextProvider);

const EntityFormContextProvider: typeof FormContextProvider = ({ children, ...props }) => {
    /**
     * In order to simplify things, we pretend to be an 'edit' form context.
     */
    return (
        <FormContextProvider {...props}>
            <formContext.Consumer>
                {(ufc) => (
                    <formTypeContext.Provider value="EDIT">
                        <editFormContext.Provider value={ufc as ContextType<typeof editFormContext>}>
                            {children}
                        </editFormContext.Provider>
                    </formTypeContext.Provider>
                )}
            </formContext.Consumer>
        </FormContextProvider>
    );
};
export { formContext, formContextHoc, EntityFormContextProvider };
